/*
|----------------------------------------------------------------------
|       Body
|----------------------------------------------------------------------
*/

body {
  @include mobile_sm {
    font-size: 1.4rem;
  }
}

#root {
  padding-top: 9rem;
  overflow: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.tag {
    @include i_flex(center);
    background: rgba($green, 0.2);
    color: $green;
    font-weight: 500;
    padding: 0.6rem 1.2rem;
    text-transform: uppercase;
    border-radius: 5rem;
  }
  &.heading {
    // text-transform: capitalize;
    > span {
      background: linear-gradient(36deg, $color, $color2);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.input {
  height: 5rem;
  border-radius: 0.5rem;
}

#oops {
  background: $light;
  .contain {
    position: relative;
    z-index: 3;
  }
  .icon {
    font-size: 16rem;
  }
}

#cover {
  background: $light;
  &:before {
    @include overlay($color, 0.07);
  }
  .content {
    color: $black;
    > h1,
    > p {
      text-shadow: none;
    }
  }
}

.check_list {
  @include list;
  @include flex($flow: wrap);
  margin: -0.2rem -1.5rem;
  > li {
    padding: 0.2rem 1.5rem;
    label {
      @include flex(center);
      span {
        margin-left: 1rem;
      }
    }
  }
}

.contain-fluid {
  max-width: none;
  padding: 0 6rem;
  @include ipad_sm {
    padding: 0 2rem;
  }
}
.contain{
  @include ipad_sm{
    padding: 0 2rem;
  }
}

/*
|----------------------------------------------------------------------
|       Header
|----------------------------------------------------------------------
*/

header {
  @include pos($pos: fixed, $b: initial, $z: 30);
  height: 9rem;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(40px);
  border-bottom: 1.5px solid #F0EFFB;
  @include trans;
  // @include ipad_sm {
  //   -webkit-box-shadow: $shadow;
  //   box-shadow: $shadow;
  // }
  &.fix {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
  }
  &.logged {
    border-bottom: none;
    box-shadow: none;
    .toggle {
      display: none;
      @include ipad_sm {
        @include flex;
      }
    }
    & ~ section:nth-of-type(1) {
      padding-top: 3rem;
    }
    &:before{
      @include pos($t:0, $l:0, $r:null, $b:0);
      content: '';
      width: 30rem;
      background-color: $color;
      border-bottom:1px solid #ffffff33;
      @include ipad{
        display: none;
      }
    }
  }
  .tog_opt{
    width: 7.8rem;
    height: 4.2rem;
    right: 10px;
    top: 1.8rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0612371f;
    border-radius: 20px;
    padding: 0 0.3rem;
    ._img{
      width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: $color2;
    padding: 0.5rem;
    margin-left: auto;
}
  }
  .toggle {
    // @include flex;
    z-index: 12;
    @include ipad_sm {
    }
  }

  #icon_btn {
    @include flex(center);
    @include list();
    margin: 0 -2rem;
    margin-left: 2rem;
    li {
      padding: 0 2rem;
      &#noti {
        .active {
          img {
            -webkit-animation: ring 4s 2s ease-in-out infinite;
            animation: ring 4s 2s ease-in-out infinite;
          }
        }
      }
      > a,
      > button {
        position: relative;
        @include flex;
        width: 2.2rem;
        min-width: 2.2rem;
        height: 2.2rem;
        background: transparent;
        padding: 0;
        border: 0;
        &.active {
          &::before {
            content: "";
          }
          > img {
            -webkit-filter: none;
            filter: none;
          }
        }
        &::before {
          position: absolute;
          top: -0.1rem;
          right: 0.2rem;
          width: 1rem;
          height: 1rem;
          background: $color;
          border: 0.2rem solid $light;
          border-radius: 75%;
          z-index: 1;
        }
        > img {
          -webkit-transition: inherit;
          transition: inherit;
        }
      }
    }
  }
  #pro_btn {
    @include flex(center);
    cursor: pointer;
    margin-left: 3rem;
    z-index: 5;
    @include mobile {
      margin-left: 2rem;
    }
    @include mobile_xs {
      margin-left: 1.5rem;
    }
    .pro_btn {
      @include flex(center);
    }
    .ico {
      width: 4rem;
      height: 4rem;
      &.icon {
        background: rgba($color, 0.1);
        border-radius: 0.6rem;
        -webkit-box-shadow: none;
        box-shadow: none;
        > img {
          -o-object-fit: contain;
          object-fit: contain;
        }
      }
    }
    .name {
      line-height: 1.2;
      margin-right: 1.5rem;
      text-align: right;
      @include mobile_md {
        display: none;
      }
      > span {
        @include flex(center, flex-end);
        color: $dark_gray;
        margin-top: 0.2rem;
        &:before {
          content: "";
          width: 1rem;
          min-width: 1rem;
          height: 1rem;
          background: $green;
          margin-right: 0.5rem;
          border-radius: 75%;
        }
      }
    }
    .drop_cnt {
      left: initial;
      right: 0;
      min-width: 18rem;
      max-height: calc(100vh - 8rem);
      overflow: auto;
    }
    .drop_lst {
      > li {
        &:nth-last-child(1) {
          > a {
            color: $red;
          }
        }
      }
    }
  }
  .nav_sub_blk {
    // width: 26rem;
    // min-width: 26rem;
    @include flex;
    padding: 2rem;
    gap: 1.5rem;
    font-size: 1.6rem;
    @include ipad_sm{
      text-align: left;
      border-bottom: 1px solid #e0e4d8;
      gap: 1rem;
    }
    .ico {
      width: 5.4rem;
      min-width: 5.4rem;
      height: 5.4rem;
      // background: rgba($color, 0.2);
      margin-bottom: 1.5rem;
      // padding: 1rem;
      border-radius: 0.7rem;
      align-self: flex-start;
      @include ipad_sm{
        width: 3.4rem;
        height: 3.4rem;
        min-width: 3.4rem;
      }
    }
    .txt{
      flex: 1;
      h4{
        font-size: 2.2rem;
        display: flex;
        gap: 1.2rem;
        margin-bottom: 0;
        @include ipad_sm{
          font-size: 1.6rem;
        }
        img{
          width: 0.7rem;
          align-self: center;
          margin-bottom: -0.5rem;
        }
        &.blue_color{
          color: $blue;
        }
        &.green_color{
          color: $green;
        }
        &.zink_color{
          color: $zink;
        }
      }
      p{
        opacity: 0.9;
        font-size: 1.7rem;
        margin-top: 1rem;
        @include ipad_sm{
          font-size: 1.2rem;
        }
      }
      a{
        color: #5F5454;
        display: flex;
        gap: 1rem;
        @include ipad_sm{
          font-size: 1.2rem;
        }
        img{
          width: 1rem;
          align-self: center;
          margin-bottom: -0.3rem;
        }
      }

    }
    
  }
}

.logo {
  position: relative;
  @include flex(center);
  width: 100%;
  max-width: 8.9rem;
  height: 4.9rem;
  > a {
    display: block;
    width: auto;
    max-width: 100%;
    height: 100%;
    > img {
      height: 100%;
      @include object(contain, left center);
      @include trans;
    }
  }
  header & {
    float: left;
    max-width: 8.9rem;
    height: 9rem;
    // margin-top: 1rem;
    @include trans;
  }
  header.logged &{
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    @include ipad{
      filter: brightness(1) invert(0);
      -webkit-filter: brightness(1) invert(0);
    }
    @include ipad_sm{
      margin-left: 5rem;
    }
    @include mobile_sm{
      max-width: 19rem;
      margin-left: 3rem;
    }
  }
  footer & {
    max-width: 17rem;
    height: auto;
    // margin-left: auto;
    margin: -.8rem 0 2rem
  }
  #oops & {
    max-width: 14rem;
    height: auto;
    margin: 0 auto 3rem;
    > a {
      width: 100%;
    }
  }
}

/*
|----------------------------------------------------------------------
|       Navigation
|----------------------------------------------------------------------
*/

nav {
  position: relative;
  @include flex(center, flex-end);
  height: 9rem;
  margin-left: auto;
  margin-top: -.2rem;
  @include ipad_sm {
    margin: 0;
  }
  header.logged & {
    margin-right: 0;
    @include ipad_sm {
      // margin-right: 4.5rem;
    }
  }
}

#nav {
  position: absolute;
  @include flex($justify: space-between);
  // -webkit-box-flex: 1;
  // -ms-flex: 1;
  // flex: 1;
  // width: 70%;
  @include trans;
  right: 0;
  left: 0;
  width: fit-content;
  margin: auto;
  @include ipad_sm {
    @include pos($pos: fixed, $t: 7rem , $r:1rem, $l:auto);
    @include flex($flow: wrap);
    width: 200px;
    background: $light;
    height: 137px;
    margin: 0;
    padding: 1rem 1rem;
    border-top: 0.2rem solid rgba($black, 0.1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    // overflow: auto;
    z-index: 3;
    border: 1px solid #D7C1A2;
    box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
    @include radius(8px);
    &.active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
  &.loged_nav{
    @include ipad_sm{
      height: 107px;
      display: none
    }
  }
  
  > ul {
    @include flex(center, center);
    @include list;
    margin: 0 -2rem;
    gap: 3rem;
    @include ipad_sm {
      width: 100%;
      -ms-flex-flow: wrap;
      flex-flow: wrap;
      margin: 0;
      gap: 0rem;
    }
    > li {
      position: relative;
      @include ipad_sm {
        width: 100%;
        padding: 0;
        // text-align: center;
        .site_btn {
          margin-top: 0.5rem;
        }
      }
      &.drop{
        padding: 4rem 0;
        @include ipad_sm{
          padding:0;
        }
      }
      > a:not(.site_btn) {
        @include flex(center, center);
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        height: 4rem;
        padding: 0 2rem;
        margin: 0 .3rem;
        // font-weight: 700;
        // font-family: 'Bold';
        color: #000;
        text-align: center;
        @include ipad_sm{
          margin: 0;
          padding: 0 .5rem;
          width: 100%;
          text-align: left;
          justify-content: flex-start;
          
        }
        &:hover,
        &.active {
          color: $color !important;
          background: #F5E8D6;
          border-radius: 0.4rem;
          @include ipad_sm{
            background: #f5e8d600;
          }
        }
      }
      > .sub {
        width: 95rem;
        @include pos($t: calc(100% - 2.7rem), $b: null, $z: 2,$l:-41.3rem);
        background: $light;
        text-align: left;
        padding: 2rem;
        margin-top: 1rem;
        visibility: hidden;
        opacity: 0;
        border: 0.1rem solid rgba($black, 0.05);
        border-radius: 0.6rem;
        -webkit-transition: none;
        transition: none;
        -webkit-box-shadow: $shadow2;
        box-shadow: $shadow2;
        pointer-events: none;
        @include trans;
        // overflow: hidden;
        @include ipad_sm {
          position: fixed;
          display: none;
          width: auto;
          max-height: none;
          background: $light;
          visibility: visible;
          opacity: 1;
          left: 25px;
          right: 25px;
          text-align: center;
          padding: 0;
          margin: 0 auto !important;
          border: 0;
          border-radius: 0;
          -webkit-transition: none;
          transition: none;
          box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.13);
        }
        &.active {
          display: block;
          pointer-events: auto;
        }
        &:before{
          @include pos($t:-25px, $b:null);
          content: '';
          border-left: 2.5rem solid transparent;
          border-bottom: 2.5rem solid #ffffff;
          border-right: 2.5rem solid transparent;
          margin: auto;
          width: 2.5rem;
          filter: drop-shadow(0px -1px 1px #ddd);
          @include ipad_sm{
            display: none;
          }
        }
        .flex_row{
          width: 100%;
          margin: 0;
          .col-md-6{
            padding: 0;
          }
        }
        > li {
          display: block;
          > a {
            display: block;
            color: $black;
            padding: 0.7rem 2rem;
            @include ipad_sm {
              @include i_flex;
            }
            &:hover {
              background: rgba($color, 0.05);
              color: $color;
            }
          }
        }
      }
      &:hover > .sub {
        visibility: visible;
        opacity: 1;
        // margin-top: 0;
        pointer-events: auto;
        z-index: 1;
      }
      &.drop > a {
        position: relative;
        &:after {
          content: "";
          @include chev;
          width: 1rem;
          height: 1rem;
          color: inherit;
          margin-left: 0.6rem;
          margin-top: 0.4rem;
          @include ipad_sm{
            position: absolute;
            right: 0;
            transform: rotate(-90deg);
          }
        }
      }
      &:not(:last-child){
        @include ipad_sm{
          > a{
            border-bottom: 1.5px solid #FBF6EF;
          }
        }
      }
    }
  }
  > ul.log_btns{
    li{
      padding: 0 .7rem;
    }
  }
  
  > #lst {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  > #cta {
    margin-left: 2rem;
    margin-right: -1rem;
    > li {
      padding: 0 1rem;
    }
    @include ipad_sm {
      margin: auto 0 0;
    }
  }
  // .responsive_logs{
  //   display: none;
  //   @include ipad_sm{
  //     display: flex;
  //   }
  // }
}

.log_btns{
  position: absolute;
  display: flex;
  gap: 1.6rem;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  right: 6rem;
  top: 2rem;
  // @include ipad_sm{
  //   display: none;
  // }
  @include mobile{
    top: 3rem;
    // padding-left: 78px;
    // right: 0;
    // left: 0;
    // justify-content: center;
  }
  > li{
    &.dashboard_dp{
      @include mobile{
        margin-top: -.8rem;
      }
    }
  }
  .site_btn{
    @include mobile{
      height: 2.8rem;
      font-size: 1rem;
      @include radius(3px);
      padding: 0 1.1rem;
    }
  }
  &.hide_cell_log_btns{
    @include ipad_sm{
      display: none;
    }
  }
}
.cell_logged_nav{
  display: none;
  @include ipad_sm{
    display: flex;
  }
  @include pos($b:null,$l:78px);
  justify-content: center;
  width: fit-content;
  margin: auto;
  > ul{
    @include list;
    @include flex;
    gap: .5rem;
    > li{
      position: relative;
      align-self: center;
      &.nav_drop{
        border-bottom: none;
        .nav_drop_btn{
          height: 2.6rem;
        }
        .sub{
          &.sm_wide{
            position: absolute;
            margin-top: 8px!important;
            padding: 2rem;
            min-width: 24.6rem !important;
            max-width: 24.6rem !important;
            left: auto!important;
            right: -78px;
            background-color: #fff!important;
            @include trans;
            visibility: hidden;
            opacity: 0;
            .site_btn{
              color: $light;
              @include ipad_sm{
                height: 3.6rem !important;
              }
            }
          }
        }
        &.active{
          .sub{
            &.sm_side{
              @include trans;
              visibility: visible;
              opacity: 1;
            }
          }
          .nav_drop_btn{
            background: #F5E8D6;
            color: $color;
          }
        }
      }
      a{
        color: #000;
        text-align: center;
        border: none;
        @include flex(center,center);
        font-size: 1.8rem;
        height: 3.6rem!important;
        padding: 0 1rem;
        @include radius(4px);
        &.active , &:hover , &:focus{
          background: #F5E8D6;
          color: $color;
        }
        @include mobile{
          font-size: .8rem;
          height: 2.6rem!important;
        }
      }
      > *:not(.sm_wide){
        font-size: 1.8rem;
          height: 3.6rem!important;
          padding: 0 1rem;
          @include radius(4px);
          @include mobile{
            font-size: .8rem;
            height: 2.6rem!important;
          }
          
      }
       
      
      
    }
  }
}
.cell_log_btns{
  display: none;
  @include ipad_sm{
    display: flex;
  }
  li{
    &:first-child{
      display: none;
    }
    .profile_btn{
      display: none;
    }
    .sub{
      &.mobile_dashboard_dp{
        top: 4rem;
        right: -40px;
        width: 18rem;
        &::before{
          display: none;
        }
        .head_user{
          margin-bottom: 1rem;
        }
        .text-center{
          margin-bottom: 1rem;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.116);
          padding-bottom: 1rem;
        }
      }
    }
  }
}
.profile_btn{
  height: 4.7rem;
  width: 4.7rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #D6E1BB;
  font-size: 1.8rem;
  color: #000;
  @include flex(center , center);
  overflow: hidden;
  img{
    @include object;
  }
}
.dashboard_dp{
  .sub {
    width: 25rem;
    @include pos($t: calc(100% - 0rem), $b: null, $z: 2,$l:null,$r:-1.4rem);
    background: $light;
    text-align: left;
    padding: 2rem;
    margin-top: 1rem;
    visibility: hidden;
    opacity: 0;
    border: 0.1rem solid rgba($black, 0.05);
    border-radius: 0.6rem;
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: $shadow2;
    box-shadow: $shadow2;
    pointer-events: none;
    @include trans;
    // overflow: hidden;
    // @include ipad_sm {
    //   position: static;
    //   display: none;
    //   width: 100%;
    //   max-height: none;
    //   background: transparent;
    //   visibility: visible;
    //   opacity: 1;
    //   text-align: center;
    //   padding: 0;
    //   margin: 0 auto !important;
    //   border: 0;
    //   border-radius: 0;
    //   -webkit-transition: none;
    //   transition: none;
    //   -webkit-box-shadow: none;
    //   box-shadow: none;
    // }
    &.active {
      display: block;
    }
    &:before{
      @include pos($t:-14px, $b:null, $l:null, $r:2rem);
      content: '';
      border-left: 1.5rem solid transparent;
      border-bottom: 1.5rem solid #ffffff;
      border-right: 1.5rem solid transparent;
      margin: auto;
      width: 1.5rem;
      filter: drop-shadow(0px -1px 1px #ddd);
    }
    .head_user{
      @include flex;
      margin-bottom: 2rem;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.116);
      padding-bottom: 1.5rem;
      gap: 1.5rem;
      .user_dp{
        height: 4.7rem;
        width: 4.7rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background: #D6E1BB;
        font-size: 1.8rem;
        color: #000;
        @include flex(center , center);
        overflow: hidden;
        img{
          @include object;
        }
      }
      .user_name{
        flex: 1;
        align-self: center;
        h6{
          font-size: 1.4rem;
          font-family: 'SemiBold';
          color: $dark;
          margin-bottom: .2rem;
        }
        p{
          font-size: 1.1rem;
          font-family: 'Regular';
          color: rgba($dark , 0.7);
        }
      }
    }
    .lst{
      @include list;
      li{
        @include flex;
        gap: .8rem;
        span{
          width: 2.1rem;
          height: 2.1rem;
          @include radius(4px);
          @include flex(center , center);
          background: #cbcbcb69;
          align-self: center;
          padding: .4rem;
          
        }
        a{
          font-size: 1.4rem;
          color: rgba($dark , 0.9);
          font-family: 'SemiBold';
          &:hover{
            color: $color;
          }
        }
        &:not(:last-child){
          margin-bottom: 1rem;
        }
      }
    }
  }
  &.active > .sub {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
  }
}

.nav_drop{
  @include ipad_sm{
    border-bottom: 1.5px solid #FBF6EF;
  }
  .nav_drop_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    display: inline-flex;
    height: 4rem;
    padding: 0 2rem;
    margin: 0 0.3rem;
    color: #000;
    text-align: center;
    border: none;
    background-color: transparent;
    @include radius(0.4rem);
    @include ipad_sm{
      padding: 0;
    }
    &:hover{
      color: $color;
      background: #F5E8D6;
      @include ipad_sm{
        background-color: transparent;
      }
    }
  }
  &.active{
    .nav_drop_btn{
      color: $color;
      background: #F5E8D6;
      @include ipad_sm{
        background-color: transparent;
      }
    }
  }
  .sub{
    margin-top: 4rem!important;
    min-width: 36.6rem!important;
    width: 36.6rem!important;
    border: 1px solid #D7C1A2!important;
    box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1)!important;
    @include radius(8px);
    left: 0!important;
    &::before{
      display: none;
    }
    .parts_searches{
      p{
        color: $dark;
      }
      button{
        display: block;
        background: #D3D5DA;
        border: none;
        @include radius(5px);
        color: #757575;
        font-size: 1.2rem;
        margin-bottom: .7rem;
        padding: 0.6rem 1.5rem;
      }
      .time{
        color: rgba($dark , 0.4);
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
    .devide_line{
      border: 1px solid #D7C1A2;
      width: 100%;
      margin-bottom: 1.5rem;
    }
    .site_btn{
      width: fit-content;
      font-size: 1.4rem;
    }
  }
  &:hover{
    .sub{
      visibility: hidden!important;
      opacity: 0!important
    }
  }
  &.active{
    .sub{
      visibility: visible!important;
      opacity: 1!important;
      z-index: 4!important;
      pointer-events: auto!important;
      display: block!important;
      &::before{
        display: none;
      }
      @include ipad_sm{
        min-width: 96% !important;
        width: 96% !important;
        padding: 2rem!important;
        text-align: left!important;
        left: 0 !important;
        // right: 0!important;
        transform: translateY(8px);
      }
    }
  }
  
}


/*
|----------------------------------------------------------------------
|       Sidebar
|----------------------------------------------------------------------
*/

#sidebar {
  @include pos($pos: fixed, $l: null, $r: -40rem, $z: 10);
  width: 100%;
  max-width: 45rem;
  background: $light;
  padding: 12rem 0 5rem;
  -webkit-box-shadow: $shadow3;
  box-shadow: $shadow3;
  @include trans;
  &.active {
    right: 0;
  }
  &:before {
    content: "";
    @include pos($pos: fixed, $r: 45rem, $z: -1);
    background: rgba($black, 0.2);
    -webkit-backdrop-filter: blur(0.5rem);
    backdrop-filter: blur(0.5rem);
  }
  .inside {
    height: 100%;
    padding: 0 4rem;
    overflow: hidden;
    overflow-y: auto;
  }
  > * {
    position: relative;
    z-index: 3;
  }
  h3 {
    margin-bottom: 4rem;
  }
  ul {
    @include list;
  }
  .list {
    margin-bottom: 6rem;
    > li {
      display: block;
      &:not(:nth-last-child(1)) {
        margin-bottom: 2rem;
      }
      > a {
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }
  .sm_list {
    > li {
      display: block;
      line-height: 1.2;
      &:not(:nth-last-child(1)) {
        margin-bottom: 2rem;
      }
      > a {
        color: $color;
        &:hover {
          color: $black;
        }
      }
    }
  }
  .nav_sub_blk {
    margin-top: 1.5rem;
    @include flex;
    width: auto;
    min-width: initial;
    .ico {
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
      margin: 0;
      margin-right: 1.5rem;
      padding: 0.7rem;
      
    }
    .txt {
      h5 {
        margin-bottom: 0.5rem;
      }
    }
  }
}

#dash_sidebar {
  @include pos($pos: fixed, $r: null, $z: 10);
  width: 30rem;
  background: $color;
  padding: 12rem 0 4rem;
  -webkit-box-shadow: $shadow2;
  box-shadow: $shadow2;
  @include trans;
  @include ipad {
    width: 22rem;
  }
  @include ipad_sm {
    width: 27rem;
    display: none;
  }
  &:after {
    @include pos($pos: fixed, $l: 22rem, $z: -1);
    background: rgba($black, 0.2);
    -webkit-backdrop-filter: blur(0.5rem);
    backdrop-filter: blur(0.5rem);
    @include ipad_sm {
      content: "";
      left: 27rem;
    }
  }
  &.active {
    display: block;
  }
  .inside {
    height: 100%;
    // padding: 0 3rem;
    overflow: hidden;
    overflow-y: auto;
    @include ipad_sm {
      padding: 0 1.5rem;
    }
  }
  .list {
    @include list;
    > li {
      display: block;
      > a {
        color: $light;
        font-size: 1.4rem;
        padding: 1.3rem 3rem;
        @include flex(center);
        &.active {
          background: rgba($light , 0.1);
          border-left: 4px solid #fff;
          > img {
            -webkit-filter: none;
            filter: none;
          }
        }
        > img {
          width: 2rem;
          min-width: 2rem;
          height: 2rem;
          margin-right: 1rem;
          
        }
      }
      .sub {
        @include list;
        display: none;
        margin-left: 3rem;
        > li {
          margin-top: 1rem;
        }
      }
    }
  }
  & ~ section {
    padding-left: 30rem;
    @include ipad {
      padding-left: 22rem;
    }
    @include ipad_sm {
      padding-left: 0;
    }
  }
}
// ===========banner=======
.banner{
  // padding:17rem 0 10rem;
  padding:0;
  // &:before{
  //   @include pos($l:null , $t:0 , $r:0, $b:0);
  //   content: '';
  //   background: url(../images/banner.svg);
  //   background-position: right;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   width: 50rem;
  // }
  .item{
    min-height: 110vh;
    max-height: 100%;
    @include flex(center , center);
    padding: 9rem 0;
    @include ipad{
      min-height: 100%;
      padding: 4rem 0;
    }
    @include ipad_sm{
      padding: 11rem 0 6rem;
    }
    @include mobile{
      padding: 20rem 0 6rem;
    }
    @include mobile_md{
      padding: 18rem 0 6rem;
    }
    @include mobile_sm{
      padding: 11rem 0 6rem;
    }
    
    .videoBanner{
      @include pos;
      &:before{
        @include pos;
        content: '';
        background: url(../images/over_lay.svg);
        @include bg;
        @include mobile_md{
          background-position: right;
        }
      }
      video{
        display: block;
        @include object(cover,center);
        @include mobile_md{
          object-position: right;
        }
      }
    }
    .contain{
      width: 100%;
      .colL{
        width: 50%;
        @include ipad_sm{
          width: 68%;
        }
        @include mobile{
          width: 60%;
        }
        @include mobile_sm{
          width: 100%;
        }
        .cntnt{
          h1{
            color: #879E4C;
            font-size: 8.3rem;
            @include ipad{
              font-size: 6.3rem;
            }
            
            @include mobile{
              font-size: 3rem;
              padding-right: 12rem;
            }
          }
        }
        .pera{
          p{
            font-size: 1.8rem;
            color: $light;
            width: 47rem;
            margin:2rem 0 4rem;
            @include ipad{
              margin-bottom: 2rem;
            }
            @include mobile_sm{
              width: 100%;
              font-size: 1.5rem;
              margin:0rem 0 0rem;
            }
          }
        }
        
        > .flex{
          margin:-1rem;
          width: 38rem;
          margin-top: 1rem;
          ._col{
            width: 50%;
            padding:1rem;
            .inner{
              padding-left:2rem;
              border-left: 3px solid #F0F7DE;
              p{
                color: $light;
                font-size: 1.8rem;
                line-height: 1;
                @include mobile_sm{
                  font-size: 1.3rem;
                }
              }
              h4{
                line-height: 0.9;
                font-family: 'Bold';
                color: $light;
              }
            }
          }
        }
        .banner_form{
          ul{
            background-color: $light;
            border-top-right-radius: .8rem;
            border-top-left-radius: .8rem;
            width: fit-content;
            border-bottom: none;
            margin-bottom: .4rem;
            li{
              button{
                font-size: 1.7rem;
                color: $black;
                position: relative;
                padding: 10px 17px;
                border-radius: 0;
                background-color: $light;
                border: none;
                &:before{
                  @include pos($l:0, $r:0, $b:0 , $t:null);
                  content: '';
                  height: .25rem;
                  background-color: $color;
                  opacity: 0;
                  -webkit-transition: all linear 0.3s;
                  transition: all linear 0.3s;
                }
                &:hover{
                  background-color: transparent;
                }
                &:disabled , &.focus , &:hover{
                  color: $color;
                  font-family: 'Bold';
                  border: none;
                  
                  &:before{
                    opacity: 1;
                  }
                }
              }
              
              
            }
          }
          .sm_form_ban{
            background-color: $light;
            box-shadow: $shadow;
            @include radius(8px);
            .flex{
              padding:2rem;
              justify-content: space-between;
              .form_grp{
                align-self: center;
                flex: unset;
                width: 100%;
                label{
                  font-size: 1.5rem;
                  color: rgba(0, 22, 25, 0.7);
                  
                }
                .input{
                  color: $black;
                  font-family: 'Bold';
                  font-size: 1.7rem;
                  padding: 0;
                  border: none;
                  height: 2.1rem;
                  
                }
                .input_relative{
                  position: relative;
                  span{
                    width: 1.5rem;
                    height: 1.5rem;
                    position: absolute;
                    bottom:.2rem;
                    right: -1rem;
                  }
                }
              }
              .form_grp:nth-child(1){
                flex: 1.3;
                @include mobile_md{
                  flex: 1;
                }
              }
              .form_grp:nth-child(2){
                flex: 1.5;
                padding:0 3rem;
                position: relative;
                padding-left: 2rem;
                
                &:before{
                  @include pos($t:4px ,$l:-15px, $r:null, $b:2px);
                  content: '';
                  width: 1px;
                  background-color: #CED9B2;
                }
                // &:after{
                //   @include pos($t:4px ,$l:null, $r:0, $b:2px);
                //   content: '';
                //   width: 1px;
                //   background-color: #CED9B2;
                // }
              }
              .form_grp:nth-child(3){
                padding-top: 1.5rem;
                border-top: 1px solid #CED9B2;
                margin-top: 2rem;
              }
            }
          }
        }
      }
    }
  }
  &.show_cell_banner{
    display: none;
    @include ipad_sm{
      display: block;
      background: #FDFAF7;
      .item{
        padding: 4rem 0;
        padding-bottom: 6rem;
        .flex{
          .colL{
            > .flex{
              width: 100%;
              margin-bottom: 2rem;
              ._col{
                .inner{
                  h4{
                    color: $color;
                  }
                  p{
                    color: $color;
                  }
                }
              }
            }
            .banner_form{
              .sm_form_ban{
                .ban_flex{
                  flex-direction: column;
                  padding: 0;
                  // gap: 1rem;
                  > *{
                    padding:1.5rem;
                  }
                  .form_grp{
                    &:nth-child(2){
                      border-top: 1px solid #ced9b293;
                      padding: 1.5rem;
                      &:before{
                        display: none;
                      }
                      .input_relative{
                        span{
                          right: 0rem;
                        }
                      }
                      .input{
                        padding-right: 2rem;
                      }
                    }
                    &:nth-child(3){
                      border-top: none;
                      margin-top: 0;
                      padding-top: .5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @include ipad_sm{
    &.hide_cell_cnt_banner{
      .item{
        .contain{
          > .flex{
            .colL{
              > .flex{
                display: none;
              }
              .banner_form{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
// =========================type_sec=================
.type_sec{
  background-color: $light;
  padding-bottom: 6rem;
  @include ipad_sm{
    padding-bottom: 0;
  }
  .colL{
    width: 50%;
    padding-right: 6rem;
    position: relative;
    @include ipad_sm{
      width: 100%;
      padding-right: 0;
      // padding-left: 4rem;
    }
    .image{
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      overflow: hidden;
      position: relative;
      padding-bottom: 100%;
      width: 100%;
      @include mobile_sm{
        padding-bottom: 125%;
      }
      img{
        @include pos($t:0,$l:0,$b:0,$r:0);
        height: 100%;
        object-fit: cover;
        width: 100%;
        @include mobile_sm{
          object-position: left;
        }
      }
    }
    .play_lbl{
      position: absolute;
      background: #FFFFFF;
      border: 1.5px solid #F0F7DE;
      box-shadow: 0px 0px 50px rgba(104, 120, 64, 0.2);
      border-radius: 8px;
      padding:1.3rem 2rem;
      top: 2rem;
      left: -10rem;
      @include ipad{
        left: -.3rem;
      }
      @include ipad_sm{
        left: -1rem;
      }
      .play_ic{
        background: #F8FBEF;
        border: 2px solid #ECF5D6;
        width: 6.3rem;
        height: 6.3rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        padding: 1.5rem;
        @include flex(center , center);
        @include mobile_sm{
          width: 4.8rem;
          height: 4.8rem;
          padding: 1.2rem;
        }
      }
      .cntnt{
        flex: 1;
        margin-left: 2rem;
        align-self: center;
        h6{
          color: #000929;
          margin-bottom: .5rem;
          @include mobile_sm{
            font-size: 1.6rem;
          }
        }
        p{
          font-size: 1.5rem;
          color: #5B614D;
          font-family: 'Regular';
          @include mobile_sm{
            font-size: 1.3rem;
          }
        }
      }
    }
    .be_more{
      background: #FFFFFF;
      border: 1.5px solid #F0F7DE;
      box-shadow: 0px 0px 50px rgba(103, 119, 63, 0.2);
      border-radius: 8px;
      padding:2.5rem;
      width: 38.5rem;
      margin: -6rem auto 0;
      position: relative;
      @include mobile_sm{
        width: 95%;
      }
      h6{
        color: #000929;
        margin-bottom: .5rem;
        @include mobile_sm{
          font-size: 1.6rem;
        }
      }
      p{
        font-size: 1.5rem;
        color: #5B614D;
        font-family: 'Regular';
        @include mobile_sm{
          font-size: 1.3rem;
        }
      }
      .home_ico{
        background: $color;
        border: 2px solid #ECF5D6;
        width: 6.3rem;
        height: 6.3rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        padding: 1.5rem;
        position: absolute;
        top: -2.8rem;
        right: 3rem;
        @include flex(center , center);
        @include mobile_sm{
          width: 4.8rem;
          height: 4.8rem;
          padding: 1.2rem;
        }
      }
    }
  }
  .colR{
    width: 50%;
    padding-left: 3rem;
    // align-self: center;
    margin-top: 13rem;
    @include ipad_sm{
      width: 100%;
      padding-left: 0;
      margin-top: 4rem;
      text-align: center;
    }
    h2{
      margin-bottom: 2rem;
    }
    p{
      font-size: 1.8rem;
      color: #90918D;
      margin-bottom: 3rem;
    }
    .btn_blk{
      .site_btn{
        @include ipad_sm{
          width: 100%;
        }
      }
    }
  }

  .outer_nav_tabs{
    width: calc(50% - 3rem);
    right: 0;
    position: absolute;
    top: 3.5rem;
    @include ipad_sm{
      width: 100%;
      right: auto;
      position: relative;
      top: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }
    .nav-tabs{
      width: fit-content;
      border-bottom: none;
      background: rgba(251, 246, 239, 0.45);
      border: 1.5px solid #F7EDDE;
      border-radius: 8px;
      padding: 1rem;
      li{
        button{
          color: #7A7F6C;
          font-size: 1.7rem;
          border-radius: 6px;
          border: 2px solid rgba(14, 8, 84, 0);
          padding: 10px 30px;
          background: transparent;
          @include mobile_sm{
            padding: 10px 25px;
          }
          &:disabled , &:hover , &:focus{
            background: #FFFFFF;
            border: 2px solid rgba(14, 8, 84, 0.04);
            box-shadow: 0px 3px 40px rgba(14, 8, 84, 0.05);
            color: $color;
          }
        }
        
      }
    }
  }
  
}
// ===============mini_sec==============
.mini_sec{
  // padding-top: 4rem;
  box-shadow: 0px 4px 200px rgba(232, 249, 247, 0.2);
  background-color: $light;
  .contain > .flex{
    background: #FBFDF7;
    border: 1.5px solid #F0F7DE;
    border-radius: 8px;
    overflow: hidden;
      .colL{
      width: 50%;
      padding:8rem;
      padding-right: 1rem;
      @include ipad_sm{
        width: 100%;
        padding: 6rem;
        padding-right: 6rem;
      }
      @include mobile{
        width: 100%;
        padding: 3rem;
        padding-right: 3rem;
      }
      h3{
        line-height: 1.2;
        margin-bottom: 2rem;
      }
      p{
        color: #6C727F;
      }
      .flex{
        margin:-1rem;
        width: 62rem;
        margin-top: 1.5rem;
        @include mobile{
          width: 100%;
        }
        .col_c{
          width: calc(100% / 3);
          padding:1rem;
          text-align: center;
          @include mobile_sm{
            width: 100%;
            text-align: left
          }
          .inner_in{
            @include mobile_sm{
              @include flex;
              gap: 2rem;
            }
            .cn_tnt{
              @include mobile_sm{
                flex: 1;
                align-self: center;
              }
            }
          }
          .sm_icon{
            width: 6rem;
            height: 6rem;
            margin: 0 auto 1rem;
            @include mobile_sm{
              margin: 0;
            }
          }
          p{
            font-size: 1.1rem;
            color: #6C727F;
          }
          h5{
            font-size: 2.3rem;
          }
        }
      }
    }
    .colR{
      width: 50%;
      padding:0rem 0 4rem;
      @include ipad_sm{
        width: 100%;
        padding: 0;
      }
      @include mobile{
        width: 100%;
        padding: 0;
      }
    }
  }
}
// ===============cta_sec===============
.cta_sec{
  background-color: $dark_green;
  h5{
    color: #BEA787;
    font-size: 2.3rem;
    text-align: center;
  }
  h3{
    color: $light;
    text-align: center;
    margin:2rem 0;
  }
  p{
    color: #D3D5DA;
    text-align: center;
  }
  form{
    max-width: 54.3rem;
    margin: 3rem auto 2rem;
    .input_relative{
      position: relative;
      .input{
        box-shadow: 0px 30px 30px rgba(90, 90, 90, 0.1);
        height: 8rem;
        padding:2.5rem;
        &::placeholder{
          color: #000929;
          opacity: 0.5;
          font-size: 1.7rem;
        }
        @include mobile_sm{
          height: 5.6rem;
        }
      }
      .site_btn{
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        padding: 0 3.5rem;
        @include mobile_sm{
          position: relative;
          right: auto;
          top: auto;
          margin-top: 1.5rem;
          width: 100%;
        }
      }
    }
  }
  p{
    small{
      color: #9EA3AE;
      font-size: 1.3rem;
      em{
        color: $light;
        font-style: normal;
      }
    }
  }
}
// =============footer==========
footer{
  box-shadow: 0px 4px 200px rgba(232, 249, 247, 0.2);
  background-color: #FDFAF7;
  padding-top: 9rem;
  @include mobile{
    padding-top: 6rem;
  }
  .copy_right{
    border-top: 1.5px solid #E8E6F9;
    padding:3rem 0;
    margin-top: 5rem;
    ._inner{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mobile_sm{
        justify-content: center;
        gap: 2rem;
      }
      p{
        color: #242400;
        opacity: 0.5;
        margin-bottom: 0;
        align-self: center;
      }
      .social_lnks{
        list-style-type: none;
        display: flex;
        gap: 4rem;
        align-self: center;
        padding-left: 0;
        margin-bottom: 0;
        opacity: 0.5;
      }
    }
  }
  .flex{
    .col:first-child{
      width: 25%;
      padding-right: 2rem;
      .f_logo{
        width: 8.9rem;
      }
      @include mobile{
        width: 100%;
      }
    }
    .col{
      width: calc(65% / 3);
      padding:0 2rem;
      @include ipad{
        width: calc(75%/3);
      }
      @include mobile{
        width: calc(100%/3);
      }
      @include mobile_md{
        width: 50%;
        padding: 0 0 2rem;
      }
    }
  }
  h5{
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    color: #242400;
    font-family: 'Bold';
  }
  ul.lst{
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    li{
      margin-bottom: 1rem;
      a{
        color: #242400;
        opacity: 0.7;
      }
    }
  }
}
// =============logon-page==============
#logon-page{
  background-color: $light;
  position: relative;
  header{
    #nav{
      display: none;
    }
  }
}
.logon{
  max-height: 100%;
  min-height: 100vh;
  background-color: $light;
  margin-top: -9rem;
  .lg_image{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    z-index: 33;
    @include ipad_sm{
      display: none;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .colL{
    width: 50%;
    padding-right: 19rem;
    padding-top: 7rem;
    position: relative;
    h4{
      font-size: 3rem;
      color: $dark_green;
      width: 48rem;
      @include ipad_sm{
        width: 100%;
        text-align: center;
      }
    }
    .inner{
      p{
      color: #6C727F;
      // text-align: center;
      // font-family: 'SemiBold';
      @include ipad_sm{
        text-align: center;
      }
      }
      fieldset{
        margin-top: -3rem;
        > p{
          color: rgba($black , 0.5);
          font-family: 'SemiBold';
          text-align: left!important;
          margin-bottom: 3rem;
          }
          .step_from{
            @include pos($l:null , $t:2rem, $r:3rem, $b:null);
            font-size: 1.3rem;
            color: rgba($black , 0.5);
          }
          &:not(:first-child){
            display: none;
          }
      }
    }
    form{
      margin-top: 3rem;
      .blk_form{
        margin-bottom: 1.7rem;
        h6{
          font-size: 1.4rem;
          color: #000929;
        }
        .form_blk{
          .input{
            height: 4.7rem;
            background: #FBFDF7;
            border: 1px solid #F0F7DE;
          }
        }
      }
      .forgot{
        a{
          color: $color;
          font-size: 1.4rem;
        }
        text-align: center;
        margin: -1rem auto 0;
      }
      .btn_blk{
        margin-top: 2rem;
        margin-bottom: 2rem;
        .site_btn{
          width: 100%;
          &.blank{
            border: 1px solid #D6DDEB;
          }
        }
      }
      p{
        color: #6C727F;
        font-size: 1.4rem;
        text-align: center;
        a{
          color: #000929;
          font-family: 'Bold';
        }
      }
      .small_txt{
        margin-top: -1rem;
        @include flex;
        gap: .5rem;
        img{
          width: 1.2rem;
          align-self: flex-start;
          margin-top: 0.3rem;
        }
        small{
          font-size: 1.2rem;
          color: #6C727F;
        }
        &.agree_smll{
          justify-content: center
        }
      }
      .select_drop{
        .flex_drop_select{
          @include flex;
          gap: 3rem;
          color: #1D2900;
          &.selec_input{
            background-image: url(../images/drop_arrow.svg)!important;
            background-position: right 1.5rem center!important;
            background-repeat: no-repeat!important;
            padding-right: 3rem !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        .drop_cnt{
          min-width: 100%;
          max-width: 100%;
          background: #FBFDF7;
          border: 1px solid #F0F7DE;
          @include radius(8px);
          box-shadow: none;
          ul{
            padding: 0;
            li{
              > *{
                padding: 0;
                width: auto;
                background-color: transparent
              }
            }
          }
          
        }
      }
      .date_ex_flex{
        position: relative;
        margin-bottom: 2.6rem;
        .form_blk{
          @include flex(center,space-between);
          height: 4.7rem;
          background: #FBFDF7;
          border: 1px solid #F0F7DE;
          input{
            background-color: transparent;
            border: none;
            width: 50%;
            &:last-child{
              text-align: right;
            }
          }
        }
        .mini_quest{
          font-size: 1.2rem;
          color: $color;
          @include pos($l:null,$b:null);
        }
      }
      
    }
    .code_verify{
      @include flex(center, space-between);
      max-width: 30.7rem;
      @include list;
      margin: 2rem 0;
      @include ipad_sm{
        margin: 2rem auto;
      }
      li{
        position: relative;
        padding: .4rem;
        .input{
          text-align: center;
          width: 4.7rem;
        }
      }
    }
    .form_flex_half{
      gap: 1.5rem;
      .blk_form{
        flex: 1;
      }
    }
    .checkout_sm_heading{
      font-size: 1.8rem;
      font-family: 'Medium';
      color: #000;
      margin-bottom: 1.5rem;
    }
    .agree_blk_form{
      border:1px solid #F4F4F3;
      background: #FBFDF7;
      @include radius(8px);
      padding: 1.5rem;
      margin-top: 2rem;
      h6{
        font-size: 1.2rem;
        font-family: 'SemiBold';
        color:#637381;
      }
      p{
        font-size: 1.1rem;
        text-align: left;
      }
      >div{
        height: 12rem;
        overflow: auto;
        &::-webkit-scrollbar{
          width:4px;background-color:#e3e8ed00;
          @include radius(50px);
        }
        &::-webkit-scrollbar-thumb{
          background:#DDDDDD;
          @include radius(50px);
          box-shadow: 3px 8px 4px rgba(0, 0, 0, 0.07);
        }
      }
    }
    @include ipad{
      padding-right: 3rem;
      padding-left: 3rem;
    }
    @include ipad_sm{
      width: 100%;
    }
    @include mobile{
      padding: 0;
      padding-top:10rem;
    }
  }
  .colR{
    width: 50%;
    padding-left: 9.5rem;
    padding-top: 3rem;
    position: relative;
    z-index: 99;
    @include ipad{
      padding-left: 2.5rem;
    }
    @include ipad_sm{
      display: none;
    }
    &.self_center{
      align-self: center;
    }
    .property_card{
      width: 44.8rem;
    }
    .right_pera_details{
      width: 50rem;
      @include ipad{
        width: 100%;
      }
      h3{
        font-size: 3.5rem;
        color: $color;
        margin-bottom: 1.5rem;
      }
      p{
        font-size: 1.8rem;
        color: #637381;
      }
      ul{
        padding-left: 2rem;
        li{
          font-family: 'Regular';
          color: #637381;
          &:not(:last-child){
            margin-bottom: .6rem;
          }
        }
      }
    }
  }
  .pricing_check_out{
    .col_p{
      max-width: 36.5rem;
      margin: auto;
      .inner{
        background: #FFFFFF;
        box-shadow: 0px 25px 60px rgba(116, 125, 132, 0.156714);
        border-radius: 10px;
        overflow: hidden;
        .head_pricing{
          text-align: center;
          border-bottom: 2px solid #f6f7f3;
          padding: 3rem 1rem;
          transition: all linear 0.3s;
          background-color: $color;
          h5 {
            color: #ffffff;
            font-size: 2.8rem;
          }
          p {
            color: #ffffff;
            font-size: 1.5rem;
            font-family: 'Regular';
          }
        }
        .price_body{
          padding: 3rem;
          height: 20rem;
          ul{
            @include list;
            li{
              display: block;
              padding-left: 3rem;
              position: relative;
              color: #303621;
              margin-bottom: 1.5rem;
              font-size: 1.4rem;
              &:before{
                @include pos($t:1px , $l:0 , $b:null , $r:null);
                content: '';
                background-image: url(../images/check.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }
        .price_footer{
          padding: 3rem;
          text-align: center;
          h4{
            color: #303621;
            font-size: 2.6rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
.option_lbl {
  width: 100%;
  @include flex(center);
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  @include ipad_sm{
    justify-content: center;
  }
  ul {
    position: relative;
    display: flex;
    list-style-type: none;
    padding-left: 0;
    @include ipad_sm{
      gap: 10rem;
    }
    &:before{
      content: "";
      height: 1px;
      background-color: $color;
      @include pos($l:0 , $b:null , $r:21% , $t:-17px);
      @include ipad_sm{
        right: 11%;
        left: 35px;
      }
    }
    li{
      position: relative;
      font-size: 1.3rem;
      font-family: 'Regular';
      color: $color;
      @include ipad_sm{
        text-align: center;
      }
      &:nth-child(1){
        padding-left: 0;
        padding-right: 8rem;
        @include ipad_sm{
          padding-right: 0;
        }
      }
      &:after{
        @include pos($l:0 , $b:null , $r:null , $t:-24px);
        content: "";
        width: 16px;
        height: 16px;
        background-color: #D9D9D9;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        @include ipad_sm{
          margin: auto;
          right: 0;
        }
      }
      &.active{
        font-family: 'Medium';
        &:after{
            background-color: $color;
        }
      }
      
    }
  }
}
// =====================.property_card============
.property_card{
  background: $light;
  border: 1.5px solid #D6DDEB;
  box-shadow: 0px 40px 70px rgba(14, 8, 84, 0.08);
  border-radius: 8px;
  padding:2rem;
  position: relative;
  .image{
    width: 100%;
    height: 21.2rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .property_badge{
    position: absolute;
    background: #B89F7C;
    border-radius: 8px 8px 8px 0px;
    @include flex;
    padding: 0.6rem 1.7rem;
    left: -1rem;
    margin-top: -1.5rem;
    gap: .5rem;
    img{
      width: 1.6rem;
      align-self: center;
    }
    span{
      text-transform: uppercase;
      color: $light;
      font-size: 1.2rem;
    }
    &:before{
      @include pos($t:null , $b:-10px,$l:0,$r:null);
      content: '';
      border-top: 1rem solid #97784C;
      border-left: 1rem solid transparent;
    }
  }
  .cntnt{
    margin-top: 4rem;
    .outer_about{
      @include flex(center , space-between);
      .abt_lbl{
        background: #FBF6EF;
        border: 1.5px solid #F5E8D6;
        border-radius: 5px;
        @include flex;
        padding: .7rem 1.5rem .7rem 1rem;
        gap: 0.9rem;
        img{
          width: 1.8rem;
          height: 1.8rem;
          align-self: center;
        }
        p{
          flex: 1;
          color: #808494;
          font-size: 1.4rem;
          a{
            text-decoration: underline;
            color: inherit;
          }
        }
      }
      .like_btn{
        button{
          border: 1.5px solid #E0DEF7;
          background: $light;
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          @include flex(center , center);
          padding: .9rem;
        }
        
      }
    }
    hr{
      margin-top: 17px;
      margin-bottom: 17px;
      border-top: 1.5px solid #F0EFFB;
    }
    .contact_flex{
      @include flex;
      gap: 2rem;
      padding-left: 0;
      margin-bottom: 0;
      li{
        @include flex;
        gap: 1.2rem;
        span{
          width: 1.9rem;
          align-self: center;
        }
        a{
          font-size: 1.4rem;
          color: #808494;
        }
      }
    }
    .owner_card{
      margin-top: 2rem;
      @include flex(center , space-between);
      background: #FBFDF7;
      border: 1.5px solid #F0EFFB;
      border-radius: 8px;
      gap: 1rem;
      padding: 1.8rem;
      .left_own{
        p{
          font-family: 'Bold';
          font-size: 1.4rem;
          color: #000929;
          margin-bottom: 1rem;
        }
        h5{
          margin-bottom: 0;
          font-size: 2.3rem;
          font-family:'Black';
          color: $color;
        }
      }
      .contact_drop{
        @include flex(center);
        background: #252511;
        border-radius: 8px;
        padding: 1.2rem 2.4rem;
        gap: 1rem;
        .file_img{
          width: 2rem;
        }
        span{
          align-self: center;
          font-family: 'Bold';
          font-size: 1.4rem;
          color: $light;
        }
        > img{
          width: .9rem;
          align-self: center;
        }
      }
    }
  }
}
// =================pricing===========
.pricing{
  background: $light;
  .price_heading{
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
    h5{
      font-size: 3.6rem;
      color: $color;
      margin-bottom: 1.5rem;
      @include mobile_sm{
        font-size: 3.2rem;
      }
      sub{
        font-size: 2rem;
        color: $black;
        font-family: 'Regular';
        // bottom: 0.1em;
        display: block;
        margin: 2rem 0 3rem;
      }
    }
    p{
      color: #637381;
      font-size: 1.5rem;
      font-family: 'Regular';
    }
  }
  .nav-tabs{
    width: fit-content;
    border-bottom: none;
    background: rgba(251, 246, 239, 0.45);
    border: 1.5px solid #D7C1A2;
    border-radius: 8px;
    padding: .5rem;
    margin: auto;
    margin-bottom: 3rem;
    background: $light;
    position: relative;
    z-index: 1;
    @include mobile_sm{
      width: 100%;
      @include flex;
    }
    li{
      @include mobile_sm{
        flex: 1;
      }
      button{
        color: #7A7F6C;
        font-size: 1.7rem;
        border-radius: 6px;
        border: 2px solid rgba(14, 8, 84, 0);
        padding: 10px 30px;
        background-color: transparent;
        position: relative;
        @include mobile_sm{
          padding: 10px 25px;
          width: 100%;
        }
        &:disabled , &:hover , &:focus{
          background: $color;
            border: 2px solid rgba(14, 8, 84, 0.04);
            box-shadow: 0px 3px 40px rgba(14, 8, 84, 0.05);
            color: $light;
            span{
              color: $light;
            }
        }
        span{
          display: block;
          @include pos($t:null , $b:-2px);
          font-size: 1.1rem;
          color: #637381;
        }
      }
      &:nth-child(1){
        button{
          margin-right: .5rem;
          
        }
      }
      &:nth-child(2){
        button{
          margin-left: .5rem;
          @include mobile_sm{
            margin-left: 0;
          }
        }
      }
      
    }
  }
  &:after{
    @include pos($t:0 , $l:0 , $r:0 , $b:52%);
    content: '';
    background: #F8F8F7;
  }
}
.price_flex{
  margin:0 -1.5rem;
  position: relative;
  z-index: 1;
  @include ipad_sm{
    justify-content: center;
  }
  .col{
    width: calc(100% / 3);
    padding:1.5rem;
    @include ipad_sm{
      width: 50%;
    }
    @include mobile_sm{
      width: 100%;
    }
    .inner{
      background: #FFFFFF;
      box-shadow: 0px 25px 60px rgba(116, 125, 132, 0.156714);
      border-radius: 10px;
      overflow: hidden;
      height: 100%;
      .head_pricing{
        text-align: center;
        border-bottom: 2px solid #f6f7f3;
        padding:3rem 1rem;
        -webkit-transition: all linear 0.3s;
      transition: all linear 0.3s;
        h5{
          color: #303621;
          font-size: 2.8rem;
          @include mobile_sm{
            font-size: 2.4rem;
          }
        }
        p{
          color: #798163;
          font-size: 1.5rem;
          font-family: 'Regular';
          @include mobile_sm{
            font-size: 1.4rem;
          }
        }
      }
      &:hover{
        .head_pricing{
          background-color: $color;
          -webkit-transition: all linear 0.3s;
      transition: all linear 0.3s;
          h5{
            color: $light;
          }
          p{
            color: $light;
          }
        }
      }
      .price_body{
        padding:3rem;
        height: 30rem;
        @include mobile_md{
          padding: 2rem;
        }
        ul{
          list-style-type: none;
          margin-bottom: 0;
          padding-left: 0;
          li{
            display: block;
            padding-left: 3rem;
            position: relative;
            color: #303621;
            margin-bottom: 1.5rem;
            font-size: 1.4rem;
            @include mobile_sm{
              font-size: 1.3rem;
            }
            &:before{
              @include pos($t:1px , $l:0 , $b:null , $r:null);
              content: '';
              background-image: url(../images/check.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
      .price_footer{
        padding:3rem;
        text-align: center;
        h6{
          text-transform: uppercase;
          font-size: 1.5rem;
          font-family: 'Regular';
          color: #798163;
          letter-spacing: .1rem;
          @include mobile_sm{
            font-size: 1.2rem;
          }
        }
        h4{
          color: #303621;
          font-size: 2.6rem;
          margin-bottom: 3rem;
          @include mobile_sm{
            font-size: 2rem;
          }
        }
        .blank{
          border-color: #D7C1A2;
          color: $color;
          &:hover{
            border-color: $color;
            color: $light;
          }
        }
        .mini_lbl{
          color: rgba($color,0.8);
          font-size: 1.5rem;
          margin-top: .6rem;
          @include mobile_sm{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
// =========faq============
.faq{
  background: #F8F8F7;
  h4{
    font-family: 'SemiBold';
    font-size: 3.6rem;
    color: #212B36;
    margin-bottom: 3rem;
    @include mobile_sm{
      font-size: 3.2rem;
    }
  }
}
.faqLst{
  >.faqBlk{
    position: relative;
    display: block;
    background: #ffffff00;
    padding: 20px 0;
    transition: all ease 0.5s;
    border-bottom: 1px solid #D3D8E0;
    @include trans;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    h5{
      position: relative;
      padding: 0 40px 0 25px;
      margin: 0;
      cursor: pointer;
      transition: all ease 0.5s;
      z-index: 2;
      font-size: 2.6rem;
      color: #212B36;
      font-family: 'SemiBold';
      @include mobile_sm{
        font-size: 1.7rem;
      }
      &:after{
        content: '\f0d7';
        position: absolute;
        top: 0px;
        right: 20px;
        font-size: 20px;
        font-family: 'FontAwesome';
        font-weight: bold;
      }
      &:hover{
        color: $color;
      }
    }
    &.active{
      border-radius: 10px;
      border-bottom: 1px solid #ffffff00;
      h5{
        &:after{
          content: '\f0d8';
        }
      }
      @include trans;
      background-color: $light;
    }
    .txt{
      padding: 20px 25px 5px;
      padding-right: 18rem;
      display: none;
      p{
        color: #637381;
        font-family: 'Regular';
      }
      
    }
  }
}
// ======faq==========
.faq-body{
  padding: 0!important;
  background-color: transparent!important;
  .faq-row{
    border-bottom: 1px solid #D3D8E0!important;
    background:#F8F8F7;
    .row-title-text{
      font-size: 2.2rem;
      color: #212B36;
      font-family: "SemiBold";
      @include mobile_sm{
        font-size: 1.7rem;
      }
    }
    .icon-wrapper{
      top: 24px!important;
    }
  }
  // .faq-row{
  //   &:has(.row-title.expanded){
  //     border-bottom: none!important;
  //   }
  // }
  // .row-title{
  //   &.expanded{
  //     background-color: $light;
  //   }
  // }
  // .row-content{
  //   &[aria-expanded="true"]{
  //     background-color: $light;
  //     height: 100%!important;
  //     padding: 0rem 2rem 2rem 2rem;
  //   }
  // }
}
// ==============blogs============
.blog{
  background-color: $light;
}
.feature_blog{
  h2{
    font-size: 4rem;
    margin-bottom: 3rem;
    font-family: 'Bold';
    @include mobile_md{
      text-align: center;
    }
  }
  margin-bottom: 7rem;
}
.all_post{
  h4{
    font-size: 3.6rem;
    font-family: 'Medium';
    margin-bottom: 3rem;
    @include mobile_md{
      font-size: 2.6rem;
    }
  }
}

.dir_col{
  box-shadow: 0px 6px 40px rgba(171, 178, 153, 0.277153);
  border-radius: 10px;
  overflow: hidden;
  @include mobile_md{
    flex-direction: column;
  }
  .image{
    position: relative;
    overflow: hidden;
    width: 50%;
    padding-bottom: 26%;
    display: block;
    @include mobile_md{
      width: 100%;
      padding-bottom: 55%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    img{
      @include pos($t:0,$b:0,$l:0,$r:0);
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .cntnt{
    width: 50%;
    padding: 3rem 3rem;
    align-self: center;
    @include mobile_md{
      width: 100%;
    }
    .cat_lbl{
      font-family: 'Regular';
      font-size: 1.5rem;
      color: #637381;
      @include mobile_md{
        font-size: 1.4rem;
      }
    }
    h4{
      a{
        color: #121900;
        font-size: 3.6rem;
        font-family: 'Medium';
        &:hover{
          color: $color;
        }
        @include mobile{
          font-size: 2.3rem;
        }
        @include mobile_md{
          font-size: 2.2rem;
        }
      }
      margin:1.7rem 0;
      line-height: 1.2;
    }
    p{
      color: #637381;
      font-size: 1.4rem;
      line-height: 24px;
      padding-right: 90px;
      @include mobile{
        padding-right: 0;
      }
      @include mobile_md{
        font-size: 1.3rem;
      }
    }
  }
}
.post_flex{
  margin:-1rem;
  .col{
    width: calc(100% / 3);
    padding:1rem;
    @include ipad_sm{
      width: 50%;
    }
    &:nth-child(1){
      width: 50%;
    }
    &:nth-child(2){
      width: 50%;
    }
    .post_blk{
      .image{
        position: relative;
        overflow: hidden;
        padding-bottom: 52%;
        display: block;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        img{
          @include pos($t:0,$b:0,$l:0,$r:0);
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .cntnt{
        padding:3rem 0;
        @include mobile_md{
          padding: 1rem 0;
        }
        .cat_lbl{
          font-family: 'Regular';
          font-size: 1.5rem;
          color: #637381;
          @include mobile_md{
            font-size: 1rem;
          }
        }
        h4{
          a{
            color: #121900;
            font-size: 2.4rem;
            font-family: 'Medium';
            &:hover{
              color: $color;
            }
            @include mobile_md{
              font-size: 1.3rem;
            }
          }
          @include mobile_md{
            line-height: 1.3rem;
          }
          margin:0rem 0 1rem;
          line-height: 0.9;
        }
        p{
          color: #637381;
          font-size: 1.4rem;
          line-height: 24px;
          padding-right: 90px;
          @include mobile{
            padding-right: 0;
          }
          @include mobile_md{
            font-size: 1.1rem;
            line-height: 1.6rem;
            padding-right: 0px;
          }
        }
      }
    }
  }
}
// ===============blog details=============
.blog_image{
  position: relative;
  border-radius: 10px;
  padding-bottom: 54%;
  overflow: hidden;
  > img{
    @include pos($t:0,$b:0,$l:0,$r:0);
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  a{
    position: absolute;
    top: 2.5rem;
    left: 5rem;
    @include flex;
    z-index: 1;
    gap: 1rem;
    font-family: 'Bold';
    color: $color;
    @include mobile_sm{
      top: 1rem;
      left: 1rem;
    }
    img{
      width: 0.8rem;
    }
  }
}
.detail_cntnt{
  max-width: 97rem;
  position: relative;
  margin: auto;
  @include ipad_sm{
    width: 95%;
  }
  
  .heading_blog{
    background-color: $light;
    padding:8rem 11rem;
    margin-top: -14rem;
    position: relative;
    @include ipad{
      padding-bottom: 3rem;
    }
    @include ipad_sm{
      padding: 3rem;
    }
    @include mobile{
      margin-top: -7rem;
    }
    h4{
      text-align: center;
      font-size: 3.8rem;
      color: #121900;
      line-height: 1.3;
      margin-bottom: 3rem;
      font-family: 'SemiBold';
      @include mobile{
        font-size: 2.8rem;
      }
      @include mobile_sm{
        font-size: 2rem;
      }
    }
  }
  .social_lnk_blog{
    position: absolute;
    padding-left: 0;
    margin-bottom: 0;
    left: -4.5rem;
    top: 17rem;
    @include ipad{
      left: unset;
      top: unset;
      position: relative;
      @include flex(center,center);
      gap: 1rem;
      margin-bottom: 2rem;
    }
    li{
      display: block;
      margin-bottom: 1.5rem;
      a{
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .blog_cntnt{
    p{
      width: 770px;
      margin: auto;
      color: #637381;
      font-size: 1.5rem;
      font-family: 'Regular';
      @include ipad_sm{
        width: 100%;
      }
    }
    h1,h2,h3,h4,h5,h6{
      width: 770px;
      margin:0 auto 1.5rem;
      color: #121900;
      @include ipad_sm{
        width: 100%;
      }
    }
    h4{
      font-size: 3.2rem;
      @include mobile_sm{
        font-size: 2.6rem;
      }
    }
  }
}

.auther_info{
  @include flex(center, center,wrap);
  .sm_icn{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    img{
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  ._cnt{
    margin-left: 1.5rem;
    h6{
      color: #212B36;
      font-size: 1.8rem;
      font-family: 'Medium';
      margin-bottom: 0;
    }
    p{
      color: #637381;
      font-size: 1.2rem;
      font-family: 'Regular';
    }
  }
}

// ==============similar_posts============
.similar_posts{
  padding-top: 0!important;
  margin-top: -2rem;
  background-color: $light;
  .main_heading{
    h4{
      margin-bottom: 3rem;
      font-size: 3.6rem;
    }
  }
  .flex{
    margin:-1rem;
    @include mobile_md{
      justify-content:center;
    }
    .col{
      width: calc(100% / 3);
      @include mobile_md{
        width: 50%;
      }
      @include mobile_sm{
        width: 100%;
      }
      padding: 1rem;
      .inner{
        .image{
          position: relative;
          overflow: hidden;
          padding-bottom: 82%;
          display: block;
          border-radius: 1rem;
          -webkit-border-radius: 1rem;
          -moz-border-radius: 1rem;
          -ms-border-radius: 1rem;
          -o-border-radius: 1rem;
          img{
            @include pos($t:0,$b:0,$l:0,$r:0);
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .cntnt{
          padding:2rem 0 0;
          h5{
            a{
              color: #121900;
              font-size: 2.2rem;
              font-family: 'SemiBold';
            }
          }
          p{
            color: #637381;
            font-size: 1.5rem;
            font-family: 'Regular';
          }
        }
        .auther_info{
          justify-content: flex-start;
        }
      }
    }
  }
}
// ===============about===========
.about{
  background: #F4F4F3;
  .main_image{
    position: relative;
    border-radius: 10px;
    padding-bottom: 54%;
    overflow: hidden;
    @include mobile_md{
      padding-bottom: 80%;
    }
    img{
      @include pos($t:0,$b:0,$l:0,$r:0);
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .main_cntnt{
    background:rgba($light , 0.95);
    max-width: 97rem;
    margin: -14rem auto 0;
    padding:7rem;
    text-align: center;
    position: relative;
    z-index: 1;
    @include ipad_sm{
      width: 95%;
    }
    @include mobile{
      padding: 3rem;
      margin: -11rem auto 0;
    }
    @include mobile_sm{
      padding: 1.5rem;
      margin: -7rem auto 0;
    }
    h2{
      color: #121900;
      font-size: 4.5rem;
      font-family: 'Bold';
      margin-bottom: 3rem;
      @include mobile{
        font-size: 3rem;
      }
      @include mobile_sm{
        font-size: 1.9rem;
        margin-bottom: 2rem;
      }
    }
    p{
      color: #212B36;
      font-size: 1.8rem;
      @include mobile_sm{
        font-size: 1.4rem;
      }
    }
  }
  .abt_flex{
    margin-top: 10rem;
    @include ipad_sm{
      margin-top: 4rem;
      flex-direction: column-reverse;
    }
    .colL{
      width: 50%;
      padding-right: 6rem;
      align-self: center;
      @include ipad_sm{
        width: 100%;
        padding-right: 0;
        padding-top: 2rem;
      }
      @include mobile{
        padding-top: 3rem;
      }
      h2{
        color: #373F43;
        font-family: 'Bold'!important;
        margin-bottom: 2rem;
        @include mobile{
          font-size: 2.6rem;
        }
      }
      p{
        color: #766B60;
        font-size: 1.7rem;
        @include mobile{
          font-size: 1.4rem;
        }
      }
    }
    .colR{
      width: 50%;
      position: relative;
      @include ipad_sm{
        width: 100%;
      }
      &:after{
        @include pos($t:-2.5rem,$b:null,$l:null,$r:0);
        content: '';
        background: #F6F0E9;
        width: 17.5rem;
        height: 19rem;
      }
      .image{
        position: relative;
        padding-bottom: 100%;
        width: 50rem;
        overflow: hidden;
        z-index: 1;
        @include ipad_sm{
          width: 100%;
        }
        img{
          @include pos($t:0,$b:0,$l:0,$r:0);
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      
    }
  }
}
// ===========abt_states==========
.abt_states{
  background-color: $light;
  // @include mobile{
  //   padding-bottom: 17rem;
  // }
  .flex{
    margin:-1rem;
    @include mobile_sm{
      justify-content: center;
    }
    .col{
      width: 25%;
      padding:1rem;
      text-align: center;
      @include mobile_sm{
        width: 120px;
      }
      h5{
        color: #373F43;
        font-family: 'SemiBold';
        font-size: 3.2rem;
        margin-bottom: .7rem;
        @include mobile{
          font-size: 2.6rem;
        }
        @include mobile_sm{
          font-size: 2.2rem;
        }
      }
      p{
        color: #766B60;
        font-size: 1.8rem;
        @include mobile{
          font-size: 1.4rem;
        }
        @include mobile_sm{
          font-size: 1.2rem;
        }
      }
      &:nth-child(2){
        margin-top: 5rem;
        @include mobile_sm{
          margin-top: 7rem;
          margin-left: -6rem;
        }
      }
      &:nth-child(3){
        margin-top: 10rem;
        @include mobile_sm{
          margin-top: 14rem;
          margin-left: -6rem;
          width: 155px;
        }
      }
      &:nth-child(4){
        margin-top: 15rem;
        @include mobile_sm{
          margin-top: 21rem;
          margin-left: -6rem;
        }
      }
    }
  }
}
// ==========abt_team=============
.abt_team{
  background-color: $light;
  padding-top: 3rem;
  padding-bottom: 4rem;
  .flex{
    margin:-1.5rem;
    .col{
      width: calc(100% / 3);
      padding:1.5rem;
      @include mobile{
        width: 100%;
      }
      &:not(:last-child){
        @include mobile{
          padding-bottom: 3rem;
        }
      }
      h3{
        font-size: 4.1rem;
        color: #373F43;
        @include ipad_sm{
          font-size: 3rem;
        }
        @include mobile{
          font-size: 2.6rem;
        }
      }
      h4{
        color: #373F43;
        font-size: 2.3rem;
        margin:2rem 0;
        @include ipad_sm{
          font-size: 1.8rem;
        }
        @include mobile{
          font-size: 2.2rem;
        }
      }
      p{
        font-family: 'Regular';
        color: #766B60;
        font-size: 1.7rem;
        margin-bottom: 2.5rem;
        @include ipad_sm{
          font-size: 1.5rem;
        }
        @include mobile{
          font-size: 1.4rem;
        }
      }
      .image{
        position: relative;
        padding-bottom: 62%;
        overflow: hidden;
        z-index: 1;
        img{
          @include pos($t:0,$b:0,$l:0,$r:0);
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &:nth-child(1){
        margin-top: -16rem;
        @include mobile{
          margin-top: 0;
        }
      }
      &:nth-child(2){
        margin-top: -8rem;
        @include mobile{
          margin-top: 0;
        }
      }
    }
  }
}
// ============abt_story==========
.abt_story{
  background-color: $light;
  &:before{
    @include pos($t:0,$b:null,$l:0,$r:45%);
    content: '';
    background: rgba(#F6F0E9, 0.3);
    height: 25.2rem;
    @include mobile{
      display: none;
    }
  }
  .flex{
    .colL{
      width: 35%;
      @include mobile{
        width: 50%;
        padding-right: .7rem;
      }
      .image{
        position: relative;
        padding-bottom: 140%;
        overflow: hidden;
        z-index: 1;
        margin-bottom: 3.5rem;
        @include mobile{
          margin-bottom: 2rem;
        }
        img{
          @include pos($t:0,$b:0,$l:0,$r:0);
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      p{
        font-size: 1.7rem;
        color: #766B60;
        font-family: 'Regular';
        margin-bottom: 2.5rem;
        @include mobile{
          font-size: 1.4rem;
        }
        @include mobile_sm{
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
      }
      a{
        color: #766B60;
        font-size: 1.7rem;
        font-family: 'Regular';
        text-decoration: underline;
        @include mobile{
          font-size: 1.4rem;
        }
        @include mobile_sm{
          font-size: 1.2rem;
        }
      }
    }
    .colR{
      width: 65%;
      padding-left: 8rem;
      padding-right: 8rem;
      margin-top: 9rem;
      position: relative;
      @include mobile{
        width: 50%;
        padding-right: 0;
        padding-left: .7rem;
        margin-top: 3rem;
      }
      &:after{
        @include pos($t:null,$b:6rem,$l:0,$r:0);
        content: '';
        background: rgba(#F2F6E9, 0.3);
        height: 19.8rem;
        @include mobile{
          display: none;
        }
      }
      h2{
        font-family: 'Bold'!important;
        font-size: 4.2rem;
        color: #373F43;
        margin-bottom: 3.5rem;
        text-transform: capitalize;
        @include ipad_sm{
          font-size: 3.2rem;
        }
        @include mobile{
          font-size: 2.2rem;
          margin-bottom: 1rem;
        }
        @include mobile_sm{
          font-size: 1.3rem;
        }
      }
      .image{
        position: relative;
        padding-bottom: 132%;
        overflow: hidden;
        z-index: 1;
        img{
          @include pos($t:0,$b:0,$l:0,$r:0);
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
// ===========abt_video=============
.abt_video{
  background: #F4F4F3;
  padding-top: 22rem;
  @include mobile{
    padding-top: 4rem;
  }
  .flex{
    position: relative;
    @include mobile{
      flex-direction: column-reverse;
    }
    &:before{
      @include pos($t:0,$b:0,$l:0,$r:8rem);
      content: '';
      background: rgba($light , 0.3);
      @include mobile{
        display: none;
      }
    }
    .colL{
      width: 50%;
      padding: 7rem;
      padding-right: 0rem;
      position: relative;
      @include mobile{
        width: 100%;
        padding:0;
        margin-top: 2rem;
      }
      h2{
        color: #373F43;
        font-family: 'Bold'!important;
        font-size: 4.2rem;
        margin-bottom: 2.5rem;
        @include ipad_sm{
          font-size: 3.2rem;
        }
        @include mobile{
          font-size: 2.6rem;
          margin-bottom: 1.5rem;
        }
      }
      p{
        font-family: 'Regular';
        color: #766B60;
        font-size: 1.7rem;
        margin-bottom: 2.5rem;
        @include mobile{
          font-size: 1.4rem;
        }
      }
      a{
        font-family: 'Regular';
        color: #766B60;
        font-size: 1.7rem;
        text-decoration: underline;
        @include mobile{
          font-size: 1.4rem;
        }
      }
    }
    .colR{
      width: 50%;
      padding-left: 8rem;
      position: relative;
      @include mobile{
        width: 100%;
        padding-left: 0;
      }
      .inner{
        width: 48.3rem;
        height: 48.3rem;
        overflow: hidden;
        margin-top: -13rem;
        margin-left: auto;
        @include ipad{
          width: 100%;
        }
        @include mobile{
          margin-top: 0;
          height: 250px;
        }
        @include mobile_sm{
          margin-top: 0;
          height: 190px;
        }
        > div{
          @include mobile{
            height: 250px!important;
          }
          @include mobile_sm{
            height: 190px!important;
          }
        }
        .play_btn{
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          width: 7rem;
          height: 7rem;
          background-color: $light;
          @include flex(center,center);
          border: none;
          margin-top: -2rem;
          @include mobile{
            margin-top: 0;
          }
          img{
            width: 2.2rem;
          }
        }
      }
    }
  }
}
// ========search_banner==========
.search_banner{
  padding:10rem 0;
  background: #F7F7F7;
  @include mobile{
    padding: 5rem 0;
  }
  h3{
    text-align: center;
    color: #362C21;
    font-size: 3.6rem;
    margin-bottom: 4rem;
    @include mobile{
      font-size: 3.2rem;
      margin-bottom: 3rem;
    }
  }
  form{
    position: relative;
    z-index: 1;
    .form_blk{
      position: relative;
      width: 57rem;
      margin: auto;
      @include mobile{
        width: 100%;
      }
      img{
        position: absolute;
        top: 1.6rem;
        left: 1.5rem;
        width: 1.65rem;
      }
      .input{
        padding-left: 4.2rem;
        &::placeholder{
          color: #BEB5AC;
          font-size: 1.4rem;
        }
      }
    }
  }
  .dim_img{
    @include pos($r:null,$t:null,$b:-5rem,$l:-6rem);
    width: 50rem;
    @include mobile_sm{
      @include pos($r:null,$t:null,$b:3rem,$l:-5rem);
      width: 23rem;
    }
  }
}
// ==============question_sec=======
.question_sec{
  background-color: $light;
  .flex{
    gap: 2rem;
    @include ipad_sm{
      flex-direction: column;
      gap: 4rem;
    }
    .col{
      flex: 1;
      h4{
        color: #362C21;
        font-size: 2.5rem;
        margin-bottom: 3.5rem;
        @include ipad_sm{
          margin-bottom: 2rem;
        }
      }
      ul{
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        li{
          display: block;
          margin-bottom: 1.1rem;
          a{
            color: #817263;
            font-size: 1.7rem;
            font-family: 'Regular';
          }
          &:last-child{
            margin-bottom: 1.5rem;
          }
        }
      }
      > a{
        color: #BEA787;
      }
    }
  }
}
// ==========data_banner=======
.data_banner{
  background: #F7F7F7;
  padding-bottom: 18rem;
  @include mobile{
    padding-bottom: 25rem;
  }
  .upload_icon{
    width: 7.3rem;
    margin: 0 auto 3rem;
  }
  h3{
    color: #212B36;
    font-size: 3.6rem;
    font-family: 'Medium';
    text-align: center;
    margin-bottom: 2rem;
    @include mobile_md{
      font-size: 3.2rem;
      margin-bottom: 1rem;
    }
  }
  p{
    color: #637381;
    font-family: 'Regular';
    text-align: center;
  }
  .dim_img{
    @include pos($r:null,$b:null);
    width: 30rem;
    @include mobile_sm{
      width: 15rem;
    }
  }
}
// ======feature_sec======
.feature_sec{
  background-color: $light;
  padding-top: 0;
  .flex{
    background-color: $light;
    box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
    border-radius: 10px;
    padding: 6rem 2rem;
    margin-top: -10rem;
    @include ipad_sm{
      padding: 2rem 2rem;
    }
    @include mobile{
      margin-top: -20rem;
    }
    @include mobile_sm{
      padding: 0;
    }
    .col{
      flex: 1;
      padding: 0rem 3.5rem;
      text-align: center;
      @include ipad_sm{
        padding: 0 2.5rem;
      }
      @include mobile{
        flex: unset;
        width: 50%;
        margin: 3rem 0;
      }
      .icon_sm{
        width: 4.8rem;
        margin: 0 auto 1.5rem;
      }
      p{
        color: #353535;
        font-family: 'Regular';
        font-size: 1.4rem;
        line-height: 2rem;
      }
      &:nth-child(1) , &:nth-child(2){
        border-right: 1px solid rgba(0, 0, 0,0.1);
        .icon_sm{
          width: 5.4rem;
        }
      }
      &:nth-child(2){
        @include mobile{
          border-right: none;
        }
      }
      &:nth-child(3){
        border-right: 1px solid rgba(0, 0, 0,0.1);
        .icon_sm{
          width: 5.4rem;
        }
      }
    }
  }
}
// ======upload_table========
.upload_table{
  background-color: $light;
  padding-top: 0;
  .table_blk{
    border: 1.5px solid #D7C1A2;
    border-radius: 8px;
    .header_tbl{
      justify-content: space-between;
      padding:2rem;
      h5{
        margin-bottom: 0;
        align-self: center;
        color: #121900;
        font-size: 2rem;
      }
    }
    .inner_tbl{
      table{
        width: 100%;
        thead{
        tr{
          th{
            background: #FDFAF7;
            padding:1rem 2rem;
            font-family: 'Bold';
            color: #121900;
          }
        }
        }
        tbody{
          tr{
            td{
              padding:1rem 2rem;
              font-family: 'Regular';
              color: #7A7F6C;
            }
            &:not(:last-child){
              border-bottom: 1px solid #FBF6EF;
            }
          }
        }
      }
    }
  }
}
// ==========help detail==========
.ans_blk{
  background-color: $light;
  padding: 7rem 0;
  .main_title{
      margin-bottom: 3rem;
      p{
        font-size: 1.7rem;
        font-family: 'Regular';
        color: #817263;
        margin-bottom: 0;
        span{
          font-size: 1.9rem;
          font-family: 'SemiBold';
          color: #817263;
          align-self: center;
        }
      }
  }
}
.ques_lst{
  @include list;
  li{
    background-color: #FDFAF7;
    position: relative;
    border: 1px solid #EEEEEE;
    box-shadow: 0px 4px 200px rgba(232, 249, 247, 0.2);
    @include radius(6px);
    padding: 3.5rem;
    @include trans;
    @include mobile_sm{
      padding: 1.5rem;
    }
    &:not(:last-child){
      margin-bottom: 2.2rem;
    }
    &:hover{
      @include trans;
      box-shadow: 0px 10px 200px rgba(90, 116, 113, 0.2);
      background-color: #d6e1bb50;
    }
    a{
      @include pos;
    }
    h5{
      font-size: 2.3rem;
      font-family: 'SemiBold';
      color: $dark;
      margin-bottom: 2.5rem;
      @include mobile_sm{
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
    }
    > p{
      color: #817263;
      font-size: 2.1rem;
      margin-bottom: 2.5rem;
      @include mobile_sm{
        font-size: 1.6rem
      }
    }
    .auther_lbl{
      @include flex;
      gap: 1.5rem;
      .auther_icon{
        width: 4.8rem;
        height: 4.8rem;
        @include radius(50%);
        background-color: #833741;
        overflow: hidden;
        @include flex(center,center);
        text-transform: uppercase;
        color: $light;
        font-size: 1.9rem;
      }
      .auther_cntnt{
        flex: 1;
        align-self: center;
        h6{
          color: #666860;
          font-size: 1.6rem;
          font-family: 'SemiBold';
          margin-bottom: .5rem;
          @include mobile_sm{
            font-size: 1.2rem;
          }
          span{
            color: 3C3F36s;
          }
        }
        p{
          color: #121900;
          opacity: 0.5;
          font-size: 1.3rem;
          @include mobile_sm{
            font-size: 1rem;
          }
        }
      }
    }
  }
  &.detail_article_help{
    li{
      h5{
        margin-bottom: .5rem;
      }
      &:hover{
        background-color: #FDFAF7;
        box-shadow: 0px 4px 200px rgba(232, 249, 247, 0.2);
      }
      .review_blk{
        background: #F7F7F7;
        padding: 3.5rem;
        h4{
          color: rgba($dark,0.6);
          font-size: 2.3rem;
          font-family: 'Medium';
        }
        .btn_blk{
          margin-top: 3rem;
          button{
            border: none;
            background-color: transparent;
            height: 7.2rem;
            width: 7.2rem;
            overflow: hidden;
            img{
              height: 100%;
              object-fit: contain;
            }
            @include mobile_md{
              flex: 1;
            }
          }
        }
      }
    }
  }
}
.blank_banner{
  padding-bottom: 9rem;
  @include mobile{
    padding-bottom: 4rem;
  }
}
.detail_sec{
  p{
    color:#817263;
    font-size: 2rem;
    @include mobile_md{
      font-size: 1.6rem;
    }
  }
}
// ========account_settings=========
.account_settings{
  background: #FDFAF7;
  @include ipad_sm{
    background-color: $light;
  }
  padding: 0 0 4rem;
  min-height: 90vh;
  height: 100%;
  .bread_crumb{
    border: 1.5px solid #FBF6EF;
    background-color: $light;
    padding: 2rem 0;
    @include ipad_sm{
      display: none;
    }
    &.active{
      @include ipad_sm{
        display: block;
      }
    }
    p{
      font-size: 1.4rem;
      font-family: 'Bold';
      color: $color;
    }
    .hide_cell_bread{
      display: block;
      @include ipad_sm{
        display: none;
      }
    }
    .hide_desk_bread{
      display: none;
      @include ipad_sm{
        @include flex;
        gap: .7rem;
        img{
          width: .8rem;
        }
        span{
          font-family: 'Bold';
        }
      }
    }
  }
  .multi_components{
    padding-top: 2rem;
    .colL{
      width: 23%;
      padding-right: 3rem;
      @include ipad{
        width: 30%;
      }
      @include ipad_sm{
        width: 100%;
        padding-right: 0
      }
      ul{
        @include list;
        li{
          width: 100%;
          button{
            border: none;
            background-color: transparent;
            color: #495039;
            padding: 1.5rem;
            width: 100%;
            text-align: left;
            border-left: 3px solid transparent;
            border-bottom: 1px solid #D7C1A2;
            @include ipad_sm{
              border-left: none;
              border-bottom: none;
            }
            &.active{
              border-left-color: #67773F;
              font-family: 'Bold';
              @include ipad_sm{
                background: #FBF6EF;
                @include radius(8px);
                border-left: none;
              }
            }
          }
        }
      }
    }
    .colR{
      width: 70%;
      padding-left: 3rem;
      @include ipad{
        padding-left: 0;
      }
      @include ipad_sm{
        width: 100%;
        position: fixed;
        top: 155px;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        z-index: 0;
        background: #FDFAF7;
        display: none;
        overflow: auto;
        padding-bottom: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }
      
      &.active{
        @include ipad_sm{
          display: block;
        }
      }
      .setting_blk{
        background-color: $light;
        @include radius(8px);
        border: 1.5px solid #D7C1A2;
        padding:2rem;
        color: #121900;
        @include ipad_sm{
          margin: auto;
          margin-top: 3rem;
        }
        &.wide_sm{
          max-width: 63.9rem;
          @include ipad_sm{
            max-width: 100%;
            width: 100%;
          }
        }
        &.wide_md{
          max-width: 78.4rem;
          @include ipad_sm{
            max-width: 100%;
            width: 100%; 
          }
        }
        &.cell_min_padding{
          @include mobile_sm{
            padding: 1rem;
          }
        }
        h4{
          font-size: 2rem;
          font-family: 'Bold';
          color: #121900;
          margin-bottom: 2.5rem
        }
        .dp_flex{
          @include flex;
          gap: 2rem;
          @include mobile_md{
            flex-wrap: wrap;
          }
          .dp_icon{
            width: 10rem;
            height: 10rem;
            overflow: hidden;
            @include radius(50%);
            img{
              object-fit: cover;
              height: 100%;
            }
          }
          .btn_blk{
            align-self: center;
            @include mobile_md{
              flex: 1;
            }
            .site_btn{
              @include mobile_md{
                width: 100%;
              }
            }
          }
        }
        form{
          h6{
            font-size: 1.4rem;
            font-family: 'Medium';
            color: #121900;
            span{
              color: #A9AE9E;
            }
          }
          .input{
            border-color: #D7C1A2;
            @include radius(8px);
          }
          .blk_form{
            margin-bottom: 2.5rem;
            &.mr_b_0{
              margin-bottom: 0;
            }
          }
          .dim_lbl{
            color: #121900;
            opacity: 0.5;
            font-family: 'Regular';
            font-size: 1.4rem;
            @include mobile_sm{
              font-size: 1.2rem;
            }
          }
          .dim_lbl1{
            color: #121900;
            opacity: 0.7;
            font-family: 'Regular';
            font-size: 1.4rem;
          }
          .sub_acc_heading{
            font-size: 1.6rem;
            font-family: 'Bold';
            color: #121900;
          }
          .PhoneInputInput{
            border: none;
            margin-left: .7rem;
            padding: 0 1rem;
            position: relative;
            border-left: 1px solid #D7C1A2;
          }
          .manage_lnk{
            margin-top: -1.3rem;
            display: block;
            color: $color;
            font-family: 'Bold';
          }
          .email_verify{
            position: relative;
            .verify_lbl{
              @include pos($r:15px,$l:null,$t:12px,$b:null);
              color: $color;
              font-family: 'Bold';
              font-size: 1.4rem;
              cursor: pointer;
            }
          }
          .pass_lnk{
            position: relative;
            @include flex(center);
            background-image: url(../images/arrow-pass.svg)!important;
            background-position: right 1.5rem center!important;
            background-repeat: no-repeat!important;
            padding-right: 3rem !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          .switch_flex{
            @include flex;
            margin-bottom: -2.5rem;
            &.cell_bottom_mrgin_none{
              @include mobile{
                margin-bottom: 0;
              }
            }
            
            .cntnt{
              flex: 1;
              margin-right: 3rem;
            }
            
          }
          .lnk_acc_flex{
            @include flex;
            gap: 1.6rem;
            @include mobile_sm{
              margin-top: 2rem;
              gap: 1rem;
            }
            .btn_blk{
              flex: 1;
              justify-content: flex-end;
            }
            h6{
              margin-bottom:0;
              align-self: center;
            }
            .social_img{
              width: 3.2rem;
              height: 3.2rem;
              align-self: center;
              @include mobile_sm{
                width: 2.4rem;
                height: 2.4rem;
              }
            }
          }
          .lnk_del_flex{
            @include flex;
            gap: 1.6rem;
            @include mobile_sm{
              flex-direction: column;
            }
            .btn_blk{
              flex: 1;
              justify-content: flex-end;
              @include mobile_sm{
                justify-content: flex-start;
              }
            }
            .cntnt{
              flex: 1;
            }
          }
          .check_lbl{
            .lbl_btn{
              margin: 1.7rem 0;
              &:last-child{
                margin-bottom: 0rem;
              }
            }
          }
          .radio_email{
            gap: 3rem;
            margin-top: 2rem;
          }
        }
        .inner_tbl{
          table{
            width: 100%;
            thead{
            tr{
              th{
                background: #FDFAF7;
                padding:1rem 2rem;
                font-family: 'Bold';
                color: #121900;
                @include ipad{
                  font-size: 1.2rem;
                  padding:1rem 1rem;
                }
              }
            }
            }
            tbody{
              tr{
                td{
                  padding:1.5rem 2rem;
                  font-family: 'Regular';
                  color: #7A7F6C;
                  @include ipad{
                    font-size: 1.2rem;
                    padding:1rem 1rem;
                  }
                }
                &:not(:last-child){
                  border-bottom: 1px solid #FBF6EF;
                }
              }
            }
            
            .down_load_lbl{
              @include flex(center , flex-start);
              gap: .6rem;
              cursor: pointer;
              span{
                font-size: 1.4rem;
                font-family: 'SemiBold';
                color: $color;
                @include ipad{
                  font-size: 1.2rem;
                }
              }
              img{
                width: 1.8rem;
                @include ipad{
                  width: 1.4rem;
                }
              }
            }
            &.cell_hide_dowload_lbl{
              .down_load_lbl{
                @include mobile_sm{
                  justify-content: flex-end;
                }
                span{
                  @include mobile_sm{
                    display: none;
                  }
                }
              }
              thead{
                tr{
                  th{
                    @include mobile_sm{
                      font-size: 1rem;
                      padding:1rem .5rem;
                    }
                  }
                }
              }
              tbody{
                tr{
                  td{
                    font-size: 1rem;
                    padding:1rem .5rem;
                  }
                }
              }
            }
          }
        }
        .mini_color_text{
          color: $color;
          font-family: 'Medium';
          font-weight: normal;
          &.mini_color_text_semi{
            font-family: 'SemiBold';
          }
        }
        .mini_text_sm{
          font-size: 1.4rem;
          font-family: 'Regular';
          margin-bottom: 2.5rem;
          a{
            font-family: 'Bold';
            color: $color;
            text-decoration: underline
          }
        }
        .flex_ul{
          @include flex;
          @include list;
          margin: .5rem 0 2.5rem;
          gap: 3rem;
          li{
            @include flex;
            gap: 2rem;
            span{
              position: relative;
              &:not(:first-child):before{
                @include pos($t:4px,$b:4px,$l:-10px,$r:null);
                content: '';
                width: .2rem;
                background-color: #DFE2D6;
              }
            }
          }
        }
      }
      .hide_cell_text_acc{
        @include mobile_sm{
          display: none!important;
        }
      }
      .cell_block{
        @include mobile_sm{
          display: block;
        }
      }
    }
  }
  .btn_blk{
    &.cell_wide_full{
      .site_btn{
        @include mobile_sm{
          width: 100%;
        }
      }
    }
  }
}
.pkg_upgrade_blk{
  margin-top: 1.5rem;
  @include radius(8px);
  max-width: 29.9rem;
  padding:2rem;
  overflow: hidden;
  &.pro_upgrade_blk{
    background-image: url(../images/bg_overlay.svg);
    background-color: $color;
    @include bg(87%, bottom -20px right);
    .pkg_badge{
      background-color: $light;
      @include radius(8px);
      @include flex;
      color: $color;
      gap: .3rem;
      width: fit-content;
      padding: .5rem 1rem;
      img{
        width: 1.6rem;
        align-self: center;
      }
      span{
        text-transform: uppercase;
        font-size: 1.4rem;
        font-family: 'Bold';
      }
    }
    .cntnt{
      margin: 3rem 0;
      p{
        font-size: 1.8rem;
        font-family: 'Regular';
        color: $light;
        margin-bottom: .5rem;
        line-height: 1;
        small{
          font-size: 1.4rem;
          font-family: 'Medium';
          padding-right: 3rem;
        }
      }
      h4{
        font-size: 2.9rem;
        font-family: 'Black';
        color: $light;
        margin-bottom: 2rem;
      }
    }
    .site_btn{
      background-color: $light;
      color: $color;
      border-color: $light;
    }
  }
  &.simple_upgrade_blk{
    background-color: $light;
    border:1px solid #D7C1A2;
    .cntnt{
      margin-bottom: 1rem;
      p{
        font-size: 1.4rem;
        font-family: 'Regular';
        padding: 0 2rem;
        margin-bottom: .3rem;
        small{
          font-size: 1.4rem;
        }
      }
      h4{
        font-size: 4.4rem;
        color: #121900;
      }
    }
    .site_btn{
      width: fit-content;
      margin: auto;
    }
    
  }
}
.divide{
  border: 1.5px solid #FBF6EF;
  background-color: #FBF6EF;
  margin: 2rem -2rem;
}
.blank{
  &.yellow_blank{
    border-color:#D7C1A2;
  }
}
.blank{
  &.red_blank{
    border-color:#FCE3E3;
    color: #F06565;
  }
}
// =================filter_nav==================
.filter_sec{
  padding:2rem 0;
  background-color: $light;
  position: fixed;
  z-index: 3;
  @include ipad_sm{
    top: 15rem;
    padding-top: .8rem;
  }
  .filter_nav{
    @include flex(center,space-between);
    gap: 1rem;
    .site_btn{
      padding: 0 1.3rem;
      font-size: 1.4rem;
      &.blank{
        color: #12190079;
        border: 1.5px solid #D7C1A2;
        &:hover{
          color: $light;
        }
      }
      &:hover{
        img{
          filter: unset;
          -webkit-filter: unset;
        }
      }
    }
    .colR{
      &.hide_cell_filter{
        @include ipad{
          display: none;
        }
      }
    }
    .colL{
      flex: 1;
      @include flex;
      gap: 1rem;
      @include mobile_sm{
        flex: unset;
        width: 100%;
      }
      .oth_filter{
        @include flex;
        gap: 1rem;
        .none_relative{
          @include ipad_sm{
            display: none;
          }
        }
        @include mobile_sm{
          flex-wrap: nowrap;
          overflow-x: auto;
          &::-webkit-scrollbar{
            width:4px;background-color:#e3e8ed00;
            @include radius(50px);
            height: 0;
          }
          &::-webkit-scrollbar-thumb{
            background:#DDDDDD;
            @include radius(50px);
            box-shadow: 3px 8px 4px rgba(0, 0, 0, 0.07);
          }
        }
        > .drop_filter{
          .yellow_blank{
            @include mobile_sm{
              height: 3.6rem;
              font-size: 1.2rem;
              padding: 0 0.7rem;
            }
          }
        }
        
      }
      @include ipad{
        flex-direction: column;
      }
      .cell_show_filters{
        display: none;
        .site_btn{
          img{
            filter: none;
          }
        }
        &.active{
          @include ipad_sm{
            display: block;
          }
        }
      }
      .cell_search_flex_field{
        @include ipad{
          @include flex(center,space-between);
          gap: 1rem;
        }
        .hide_cell_filter{
          display: none;
          @include ipad{
            display: block;
            align-self: center;
            background-color: transparent;
            border: none;
            margin-right: -8px;
          }
          img{
            @include ipad{
              width: 3.4rem;
              height: 3.4rem;
            }
          }
        }
      }
    }
    .seach_field{
      width:38.9rem;
      position: relative;
      @include mobile_sm{
        width: 100%;
      }
      span{
        @include pos($l:14px,$t:14px,$r:null,$b:null);
      }
      .input{
        height: 4.8rem;
        border: 1.5px solid #D7C1A2;
        padding-left: 5rem;
        @include radius(8px);
        &::placeholder{
          color: #121900;
          opacity: 0.5;
        }
      }
      &.after_search{
        .input{
          background: #FDFAF7;
        }
        .search_tag{
          @include pos($r:8px,$t:8px,$l:null,$b:null);
          @include flex;
          gap: .6rem;
          background-color: #F2F2F2;
          @include radius(8px);
          padding:.7rem 1rem;
          em{
            font-style: normal;
            font-size: 1.2rem;
            color: #121900;
            opacity: 0.5;
            align-self: center;
          }
          button{
            border: none;
            background-color: transparent;
            align-self: center;
            padding: 0;
            margin-bottom: -0.6rem;
          }
        }
      }
      
    }
    .drop_filter{
      position: relative;
      &.none_relative{
        position: unset;
      }
      .drop_cnt{
        border: 1px solid #D7C1A2;
        box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
        @include radius(8px);
        // opacity: 1;
        // visibility: visible;
        max-width: 64.6rem;
        min-width: 64.6rem;
        padding: 1.5rem;
        @include ipad_sm{
          max-width: 88%;
          min-width: 88%;
          position: fixed;
          right: 0;
          top: 23rem;
          margin: auto;
        }
        @include ipad_sm{
          top: 26rem;
        }
        &.active{
          opacity: 1;
          visibility: visible;
          margin-top: 1rem;
          @include mobile{
            margin-top: auto;
          }
        }
        &.wide_sm{
          max-width: 54.6rem;
          min-width: 54.6rem;
          @include ipad_sm{
            max-width: 88%;
            min-width: 88%;
          }
        }
        &.wide_lg{
          max-width: calc(100% - 120px);
          min-width: calc(100% - 120px);
          left: 0;
          right: 0;
          margin: 1rem auto 0;
        }
        &.right_drop_cnt{
          left:auto;
          right: 0;
          @include ipad_sm{
            left:0px;
          }
        }
        .head_filter{
          @include flex(center,space-between);
          margin-bottom: 1.5rem;
          p{
            align-self: center;
            font-family: 'SemiBold';
            color: $dark;
            margin-bottom: 0;
            &.dim_text{
              opacity: 0.7;
              a{
                color: $dark;
                @include mobile_sm{
                  font-size: 1rem;
                }
              }
            }
          }
        }
        form{
          .lbl_btn{
            margin-bottom: 1rem;
            input[type="checkbox"]{
              border: 1.5px solid #D7C1A2;
            }
            label{
              @include flex(center,space-between);
              flex: 1;
              span{
                font-size: 1.4rem;
                color: rgba($dark,0.7);
                &:last-child{
                  text-align: right;
                }
                small{
                  display: block;
                  opacity: 0.5;
                  font-size: 1.1rem;
                }
              }
            }
          }
          .btn_blk{
            margin-top: 2rem;
            .site_btn{
              height: 4rem;
              &.yellow_blank{
                border: 2px solid #D7C1A2;
                height: 4rem;
                color: $color;
              }
              @include mobile_sm{
                font-size: 1.2rem;
              }
            }
          }
        }
        .half_flex_form{
          .form_blk{
            margin: 1rem 0 3rem;
            &:not(:last-child){
              @include mobile_sm{
                // margin-bottom: 2rem;
              }
            }
            h6{
              font-family: 'Medium';
              font-size: 1.4rem;
              color: #121900;
            }
            .half_flex{
              @include flex;
              gap: 1rem;
              @include mobile_sm{
                flex-direction: column;
              }
              span{
                width: 1.1rem;
                height: .15rem;
                background-color: #D7C1A2;
                align-self: center;
              }
              .half_field{
                flex: 1;
                .input{
                  height: 4.8rem;
                  border: 1.5px solid #D7C1A2;
                  color: #BCC3AA;
                  @include radius(8px);
                  &::placeholder{
                    color: #BCC3AA;
                  }
                  option{
                    color: #BCC3AA;
                  }
                }
              }
            }
          }
        }
        .margin-top-neg{
          margin-top: -1rem!important;
        }
        .boolean_flex{
          @include flex;
          flex-wrap: wrap;
          gap: 1rem;
          label{
            margin-left: 0;
            &.main_color{
              &:hover{
                color: $light!important;
              }
            }
          }
        }
        h6{
          &.f_size_16{
            font-size: 1.6rem!important;
            color: $dark!important;
          }
        }
      }
      .yellow_blank{
        border: 1.5px solid #D7C1A2;
        height: 4.8rem;
        cursor: pointer;
      }
    }
    .site_btn{
      img{
        width: 2rem;
      }
    }
  }
  .input{
    height: 4.8rem;
    border: 1.5px solid #D7C1A2;
    color: #BCC3AA;
    @include radius(8px);
    &::placeholder{
      color: #BCC3AA;
    }
  }
}
.dim_dark_text{
  color: rgba($dark,0.6);
}
.f_s_15{
  font-size: 1.5rem;
}
.main_color{
  color: $color!important;
}
.f_s_14{
  font-size: 1.4rem;
}
.f_dim_light{
  color: #121900;
}
.lbl_table_search{
  border: 1.5px solid #D7C1A2;
  @include radius(8px);
  table{
    width: 100%;
    th{
      padding:.8rem 1.5rem;
      border-bottom: 1px solid #D7C1A2;
      font-size: 1.6rem;
      color: $color;
      font-family: 'Medium';
      font-weight: normal;
      @include mobile_sm{
        font-size: 1.4rem;
        padding:.8rem 1rem;
      }
    }
    td{
      // padding: 0rem 2rem;
      font-size: 1.4rem;
      color: $color;
      font-family: 'Medium';
      font-weight: normal;
      @include mobile_sm{
        font-size: 1rem;
      }
      input{
        border-color: #D7C1A2;
        margin-top: 1rem;
      }
      &.lbl_dot{
        padding-left: 3rem;
        position: relative;
        &:before{
          @include pos($l:12px,$t:17px,$b:null,$r:null);
          content: '';
          width: .7rem;
          height: .7rem;
          overflow: hidden;
          @include radius(50%);
          @include mobile_sm{
            top: 15px;
          }
        }
        &.blue_dot{
          &:before{
            background-color: #3B38ED;
          }
        }
        &.pink_dot{
          &:before{
            background-color: #ED389A;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          padding:.2rem 2rem 0rem;
          @include mobile_sm{
            padding:.2rem 1rem 0rem;
          }
        }
        &:last-child{
          td{
            padding-bottom:1rem;
          }
        }
      }
    }
  }
  
}
// ====================search_map==============
.search_map{
  padding-bottom: 0 !important;
  background-color: $light;
  @include ipad_sm{
    padding-top: 18rem;
  }
  @include mobile{
    padding-top: 17rem;
  }
  #map{
    margin-top: 1rem;
    iframe{
      height: 90vh;
    }
  }
  .contain-fluid{
    &.fix_cell_result_lbl{
      @include ipad_sm{
        @include pos($t:null);
        z-index: 1;
        background: $light;
        padding: 3rem;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        text-align: center;
        p{
          font-size: 1.6rem;
          font-family: 'Bold';
          color: $dark;
        }
      }

    }
  }
}
// ========property_listing_search===========
.property_listing_search{
  padding:4rem 0;
  background-color: #FDFAF7;
  h3{
    color: #121900;
    margin-bottom: 3rem;
  }
  .search_relative{
    position: relative;
    .input{
      background-color: $light;
      border:none;
      height: 10rem;
      padding: 3rem;
      @include radius(8px);
      &::placeholder{
        color: #121900;
        font-size: 2.2rem;
        @include mobile_md{
          font-size: 1.6rem;
        }
      }
      @include mobile_md{
        height: 6.4rem;
        padding: 1.5rem;
        padding-right: 9.5rem;
      }
    }
    .site_btn{
      @include pos($t:24px,$r:20px,$l:null,$b:null);
      height: 5.5rem;
      width: 15.6rem;
    }
}
}
.black_text{
  font-family: 'Black';
}
.color_text{
  color: $color;
}
.bold_text{
  font-family: 'Bold';
}
.f_s_24{
  font-size: 2.4rem;
}
.property_list_sec{
  padding: 4rem 0 !important;
  background-color: #FDFAF7;
  .light_heading{
    margin-bottom: 4rem;
  }
}
.property_list_grid{
  .flex{
    margin: -1.5rem;
    @include ipad_sm{
      justify-content: center;
    }
    ._col{
      width: calc(100% / 3);
      padding:1.5rem;
      position: relative;
      @include ipad_sm{
        width: 50%;
      }
      @include mobile_md{
        width: 100%;
      }
      .property_badge{
        position: absolute;
        background: $color;
        border-radius: 8px 8px 8px 0px;
        @include flex;
        padding: 0.6rem 1.7rem;
        left: .5rem;
        margin-top: -1.5rem;
        gap: .5rem;
        z-index: 1;
        img{
          width: 1.6rem;
          align-self: center;
        }
        span{
          text-transform: uppercase;
          color: $light;
          font-size: 1.2rem;
        }
        &:before{
          @include pos($t:null , $b:-10px,$l:0,$r:null);
          content: '';
          border-top: 1rem solid $color;
          border-left: 1rem solid transparent;
        }
      }
      .inner{
        @include block;
        position: relative;
        .abst_link{
          @include pos;
          z-index: 1;
        }
        .cntnt{
          padding-right: 3rem;
          padding-top: 1rem;
          position: relative;
          h4{
            sub{
              font-size: 1.4rem;
              font-family: 'Medium';
              color: #121900;
              opacity: 0.5;
            }
          }
          p{
            color: #121900;
            opacity: 0.5;
          }
          .like_btn{
            @include pos($t:7px,$l:null,$b:null,$r:0);
            button{
              border: 1.5px solid #D7C1A2;
              background: $light;
              width: 4.8rem;
              height: 4.8rem;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              @include flex(center , center);
              padding: .9rem;
            }
          }
        }
        .divide_line{
          width: 100%;
          height: 1.5px;
          background-color: #FBF6EF;
        }
        ul{
          @include list;
          @include flex;
          margin-top: 1.5rem;
          gap: 1.5rem;
          li{
            @include flex;
            gap: .8rem;
            img{
              width: 1.7rem;
              align-self: center;
            }
             > span{
              align-self: center;
              opacity: 0.7;
              color: #121900;
              font-size: 1.4rem;
              span{
                color: #121900;
                font-size: 1.4rem;
                @include ipad{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
// =============viewd_owners_list===========
.viewd_owners_list{
  padding-top: 2rem !important;
  background-color: #FDFAF7;
  .light_heading{
    margin-bottom: 3.5rem;
  }
  .flex{
    margin:-1rem;
    @include ipad_sm{
      justify-content: center;
    }
    ._col{
      width: 50%;
      padding:1rem;
      @include mobile_md{
        width: 100%;
      }
      .inner{
        padding:2rem;
        border: 1.5px solid #D7C1A2;
        @include radius(8px);
        p{
          color: #121900;
          opacity: 0.5;
        }
        .inner_flex{
          @include flex;
          @include ipad_sm{
            flex-direction: column;
          }
          .cntnt{
            flex: 1;
            margin-right: 2rem;
            @include flex;
            @include ipad_sm{
              padding-right: 0;
            }
            .sm_icon{
              width: 4.8rem;
              height: 4.8rem;
              @include flex(center,center);
              background: #833741;
              @include radius(50%);
              overflow: hidden;
              span{
                font-size: 2rem;
                font-family: 'SemiBold';
                color: $light;
              }
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            ._cntnt{
              margin-left: 1rem;
              align-self: center;
              h6{
                color: #121900;
                font-size: 1.6rem;
                margin-bottom: .5rem;
              }
              p{
                font-size: 1.4rem;
              }
            }
          }
          .btn_blk{
            align-self: center;
            @include ipad_sm{
              margin-top: 1.5rem;
              padding-top: 1.5rem;
              border-top: 1.5px solid #DEDEDE;
              width: 100%;
            }
            .yellow_btn{
              background: #F5E8D6;
              color: $color!important;
              border-color: #F5E8D6;
              img{
                filter: unset;
                -webkit-filter: unset;
                width: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}
.property_post{
  padding-top: 5rem!important;
  padding-bottom: 11.5rem!important;
  background-color: #ffffff;
  margin-top: 4rem;
  @include mobile{
    padding-top: 0rem!important;
  }
  .light_heading{
    margin-bottom: 3.5rem;
  }
  #owl-post{
    .item{
      .inner{
        position: relative;
        a{
          @include pos;
        }
        .image{
          @include img(80%);
          img{
            @include object(cover,center);
            @include pos;
          }
        }
        .cntnt{
          padding-top: 2rem;
          @include mobile_sm{
            padding-top: 1rem;
          }
          h4{
            font-family: 'Medium';
            font-size: 2.4rem;
            color: #212B36;
            @include trans;
            @include mobile_sm{
              font-size: 1.3rem;
              margin-bottom: 0;
            }
          }
        }
        &:hover{
          .cntnt{
            h4{
              @include trans;
              color: $color;
            }
          }
        }
      }
    }
    .owl-nav{
      top: auto;
      bottom: -20px;
      @include flex(center,center);
      .owl-prev{
        left: auto;
        margin-left: -5rem;
        border: 1px solid #D7C1A2;
      }
      .owl-next{
        right:auto;
        margin-right: -5rem;
        border: 1px solid #D7C1A2;
      }
    }
  }
}

.tabs_filter{
  .react-tabs__tab-list{
    border-bottom: none;
    @include ipad{
      flex-wrap: wrap;
    }
    @include flex;
    gap: 1rem;
    .react-tabs__tab{
      padding: 0;
      border:none;
      button{
        &.blank{
          height: 3.6rem;
          border: 1.5px solid #F7F3EC;
          @include radius(8px);
          color: #12190048;
        }
      }
      &.react-tabs__tab--selected{
        background-color: transparent;
        @include radius(0);
        button{
          &.blank{
            border: 1.5px solid #D7C1A2;
            color: #1219007e;
          }
        }
      }
      
    }
  }
  .react-tabs__tab-panel{
    margin-top: 1.5rem;
    .react-tabs__tab-list{
      border-bottom: 1px solid rgba($dark , 0.4);
      width: fit-content;
      gap: 3rem;
      .react-tabs__tab{
        // border-bottom: 1px solid transparent;
        padding-left: 0;
        button{
          border: none;
          background-color: transparent;
          color: rgba($dark , 0.4);
          font-size: 1.4rem;
          font-family: 'SemiBold';
          padding:0;
        }
        &.react-tabs__tab--selected{
          button{
            color: rgba($dark , 1);
            border-bottom: 1px solid #000;
          }
        }
      }
    }
  }
}
.check_flex{
  margin-top: 1.8rem;
  gap: 5rem;
  .col{
    flex: 1;
    >ul{
      >li{
        &:not(:last-child){
          margin-bottom: 2rem;
        }
      }
    }
    ul{
      @include list;
      li{
        .lbl_btn{
          label{
            @include flex(center,space-between);
            span{
              color: $dark!important;
              font-size: 1.4rem!important;
              &:last-child{
                text-align: right;
              }
            }
          }
        }
        ul{
          padding-left: 2.8rem;
          li{
            .lbl_btn{
              input[type="checkbox"]{
                width: 1.7rem;
                min-width: 1.7rem;
                height: 1.7rem;
              }
              label{
                margin-bottom: -3px;
                span{
                  color:rgba($dark,0.8)!important;
                  font-size: 1.2rem!important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mix_half_flex{
.col{
  width: 50%;
  &:nth-child(even){
    padding-left: 2rem;
  }
  &:nth-child(odd){
    padding-right: 2rem;
  }
  &.nested_half_flex{
    @include flex;
    gap: 3.4rem;
    .form_blk{
      flex: 1;
    }
  }
}
}
.owner_flex{
  .col{
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .half_flex_form{
    .form_blk{
      margin: 0!important;
    }
  }
  .lbl_btn{
    margin-bottom: 0!important;
    label{
      font-size: 1.5rem;
      color: rgba($dark , 0.6);
    }
  }
  .site_btn{
    &.yellow_blank{
      border: 2px solid #D7C1A2!important;
    }
    
  }
}
.info_lbl_lbl{
  @include flex;
  gap: .7rem;
  margin-bottom: 1rem;
  h6{
    font-family: 'Medium';
    font-size: 1.4rem;
    color: #121900;
    margin-bottom: 0;
    align-self: center;
  }
  .info_span{
    align-self: center;
    position: relative;
  }
  .span_type_lbl{
    @include flex(center,flex-end);
    flex: 1;
    gap: .7rem;
    .btn_sm_span{
      border: none;
      text-transform: uppercase;
      color: #121900;
      font-size: 1rem;
      @include radius(4px);
      padding:.2rem .6rem;
      background-color: transparent;
      cursor: pointer;
      &.fil_btn{
        color: $color;
        background: #F5E8D6;
      }
    }
  }
}
.padding_right_span{
  padding-right: 11.5rem;
}
.doted_flex{
  @include flex;
  gap: 1rem;
  &.purple_clr{
    span{
      &.site_btn{
        background-color: #5B638D;
        border-color: #5B638D;
        height: 4rem;
      }
      &.doted_line{
        flex: 1;
        border: 1px dashed #5B638D;
        align-self: center;
      }
    }
  }
  &.green_clr{
    span{
      &.site_btn{
        background-color: #5B8D87;
        border-color: #5B8D87;
        height: 4rem;
      }
      &.doted_line{
        flex: 1;
        border: 1px dashed #5B8D87;
        align-self: center;
      }
    }
  }
  p{
    margin-bottom: 0px;
    font-size: 1.4rem;
    color: #000;
    align-self: center;
  }
}
.sm_br_gap{
  margin-bottom: 2rem;
}
.footer_filter_btn{
  @include flex(center , space-between);
  margin-top: 2rem;
  .text_left{
    align-self: center;
    p{
      margin-bottom: 0;
      font-size: 1.4rem;
      color: rgba($dark , 0.3);
      position: relative;
      padding-left: 1.3rem;
      &:before{
        @include pos($r:null,$b:null,$t:9px,$l:0);
        content: '';
        width: .5rem;
        height: .5rem;
        @include radius(50px);
        background-color: rgba($dark , 0.3);
      }
    }
  }
  .btn_blk{
    margin-top: 0!important;
  }
}
// ==================result_filter=================
.result_filter{
  border-top: 1px solid rgba(148, 138, 128, 0.25);
  border-bottom: 1px solid rgba(148, 138, 128, 0.25);
  background-color: $light;
  padding:2rem 0;
  position: fixed;
  z-index: 2;
  top: 178px;
  @include ipad{
    top: 225px;
  }
  @include ipad_sm{
    top: 284px;
  }
  @include mobile_sm{
    top: 272px;
  }
  &.detail_filter_flex{
    border-top: none;
    padding-top: 0;
    @include ipad{
      padding-top: 2rem;
      border-top: 1px solid rgba(148, 138, 128, 0.25);
    }
  }
  .filter_result_flex{
    @include flex(center , space-between);
    @include ipad_sm{
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    @include mobile_sm{
      align-items: center;
    }
    &.detail_filter_result_flex{
      @include mobile_sm{
        align-items: flex-start;
      }
    }
    ._col{
      .filter_blk{
        @include flex;
        gap: 1rem;
        .site_btn{
          padding: 0 1.3rem;
          font-size: 1.4rem;
          height: 4.8rem;
          @include mobile_sm{
            height: 3.6rem;
            font-size: 1.2rem;
            padding: 0px 0.7rem;
          }
          img{
            filter: none;
              -webkit-filter: none;
              width: 2rem;
          }
          &.yellow_blank{
            color: #12190079;
            border: 1.5px solid #D7C1A2;
            &:hover{
              color: $light;
            }
          }
          &:hover{
            img{
              filter: none;
              -webkit-filter: none;
            }
          }
        }
      }
      .togg_icon_search{
        align-self: center;
        height: 4rem;
        width: 4rem;
        background: #F7F7F7;
        @include radius(8px);
        border: 1.5px solid transparent;
        @include flex(center,center);
        @include mobile_sm{
          width: 3.6rem;
          height: 3.6rem;
        }
        &.active{
          background-color: transparent;
          border: 1.5px solid #D7C1A2;
        }
      }
      
      &:nth-child(3){
        @include ipad{
          display: none;
        }
      }
    }
  }
  .drop_result_btn{
    position: relative;
    .drop_cnt{
      background: $light;
      border: 1px solid #D7C1A2;
      box-shadow: 0px 10px 10px rgba(16, 10, 85, 0.1);
      padding:2rem;
      @include radius(8px);
      @include ipad_sm{
        max-width: 88%;
        min-width: 88%;
        position: fixed;
        right: 0;
        top: 36rem;
        margin: auto;
      }
      @include mobile_sm{
        top: 33.7rem;
      }
      &.medium{
        width: 340px;
        min-width: 340px;
      }
      &.exact_wide{
        padding: 1rem!important;
        width: 100%;
      }
      &.active{
        margin-top: 1rem!important;
        @include ipad_sm{
          margin-top: 0!important;
        }
      }
      h5{
        color: #121900;
        font-size: 1.8rem;
        font-family: 'Bold';
        margin-bottom: 2rem;
      }
      form{
        .site_btn{
          width: 100%;
        }
        .form_blk{
          &:not(:nth-last-child(1)){
            margin-bottom: 1rem!important;
          }
          h6{
            font-size: 14px;
            color: #121900;
            font-family: 'Medium';
          }
          .input{
            border: 1.5px solid #D7C1A2;
            @include radius(8px);
            height: 4.8rem;
          }
          .inner{
            cursor: pointer;
            background: #FDFAF7;
            border: 1px dashed #D7C1A2;
            @include radius(8px);
            padding: 2rem;
            .carbe_icon{
              width: 2rem;
              margin: 0 auto 1rem;
            }
            h6{
              font-size: 1.7rem;
              font-family: 'SemiBold';
              color: #121900;
              opacity: 0.6;
            }
          }
        }
      }
      .option_lst{
        button{
          display: block;
          width: 100%;
          border: none;
          background-color: $light;
          height: 4rem;
          @include flex(center);
          @include radius(8px);
          color: #25251175;
          font-size: 1.4rem;
          &.active , &:hover{
            background: #FBF6EF;
            color: $color;
          }
          @include mobile_sm{
            font-size: 1.2rem;
          }
        }
      }
    }
    &.cell_top_high_mrgn{
      .drop_cnt{
        @include ipad_sm{
          max-width: 100%;
          min-width: 100%;
          position: absolute;
          right: 0;
          top: 57px;
          margin: auto;
        }
        @include mobile_sm{
          top: 47px;
        }
      }
    }
  }
}
html::-webkit-scrollbar
{width:4px;background-color:#e3e8ed;border-radius:50px}
html::-webkit-scrollbar-thumb
{background:rgba(0,0,0,.2);border-radius:50px}
// ===========result=======
.search_result_page {
  padding: 0px 0px 0px !important;
  margin-top:178px;
  background-color: $light;
  &.filter_show_after{
    @include ipad{
      margin-top: 222px;
    }
    @include ipad_sm{
      margin-top: 343px;
    }
    @include mobile_sm{
      margin-top: 305px;
    }
  }
  .listing_relative{
    position: relative;
    .colL{
      width: 50%;
      min-height: 600px;
      padding: 2.5rem;
      @include ipad_sm{
        width: 100%;
        min-height: 100%;
      }
      .outer_properties{
        .inner_properties{
          margin-bottom: 2rem;
          padding: 2rem;
          @include radius(8px);
          border: 1.5px solid #F9F1E7;
          background-color: $light;
          position: relative;
          @include mobile{
            padding: 1.5rem;
          }
          .dt_cnt{
            @include flex;
            border-bottom: 1.5px solid #F9F1E7;
            padding-bottom: 1.5rem;
            @include mobile{
              flex-wrap: wrap;
            }
            .col{
              &:nth-child(1){
                width: 60%;
                padding-right: 3rem;
                @include mobile{
                  width: 100%;
                  padding-right: 0;
                }
              }
              &:nth-child(2){
                width: 30%;
                padding-left: 3rem;
                align-self: flex-end;
                @include mobile{
                  width: 100%;
                  padding-left: 0;
                }
              }
              @include mobile{
                &.hide_cell_text_col{
                  display: none;
                }
              }
              h5{
                color: rgba(#121900,0.5);
                font-size: 1.4rem;
                font-family: 'Medium';
                margin-bottom: 0;
                strong{
                  color: $color;
                  font-size: 1.6rem;
                  font-family: 'Black';
                }
              }
              h3{
                a{
                  font-size: 2rem;
                  font-family: 'Bold';
                  color: #121900;
                }
                @include mobile{
                  margin-top: 1rem;
                  margin-bottom: .5rem;
                }
              }
              p{
                &.dim_text{
                  font-size: 1.4rem;
                  color: rgba(#121900,0.5);
                }
              }
              h4{
                font-size: 1.6rem;
                color: #121900;
                font-family: 'SemiBold';
              }
            }
          }
          .dt_foot{
            @include flex;
            @include mobile{
              flex-wrap: wrap;
            }
            .col{
              &:nth-child(1){
                width: 60%;
                padding-right: 3rem;
                @include mobile{
                  width: 100%;
                  padding-right: 0;
                  border-bottom: 1.5px solid #F9F1E7;
                  padding: 1.5rem 0;
                }
              }
              &:nth-child(2){
                width: 30%;
                padding-left: 3rem;
                align-self: flex-end;
                @include mobile{
                  width: 100%;
                  padding-left: 0;
                  padding-top: 1.5rem;
                  padding-bottom: .5rem;
                }
              }
              .show_cell_text_col{
                display: none;
                @include mobile{
                  display: block;
                  margin-bottom: -1rem;
                  h4{
                    font-size: 1.6rem;
                    color: #121900;
                    font-family: 'SemiBold';
                    margin-bottom: .5rem;
                  }
                  p{
                    font-size: 1.4rem;
                    color: rgba(18, 25, 0, 0.5);
                  }
                }
              }
              ul{
                &.info{
                  @include list;
                  @include flex;
                  margin-top: 2rem;
                  gap: 1.5rem;
                  @include mobile{
                    margin-top: 0;
                  }
                  li{
                    @include flex;
                    gap: .8rem;
                    img{
                      width: 1.7rem;
                      align-self: center;
                    }
                    span{
                      align-self: center;
                      opacity: 0.7;
                      color: #121900;
                      font-size: 1.4rem;
                    }
                  }
                }
                &.social{
                  @include list;
                  @include flex;
                  margin-top: 2rem;
                  gap: .7rem;
                  li{
                    a{
                      width: 2.3rem;
                      height: 2.3rem;
                      @include flex(center,center);
                      @include radius(50px);
                      background: #EDEEEC;
                      @include trans;
                      img{
                        width: 1.3rem;
                        @include trans;
                      }
                      &:hover{
                        @include trans;
                        background-color:#f5e8d6;
                      }
                    }
                  }
                }
              }
            }
          }
          .cta_opt_lbl{
            @include pos($t:2rem,$r:2rem,$b:null,$l:null);
            @include flex;
            gap: .7rem;
            @include mobile{
              top: 1.3rem;
            }
            a{
              border: 1.5px solid #D7C1A2;
              @include radius(50px);
              width: 3rem;
              height: 3rem;
              @include flex(center,center);
              @include trans;
              @include mobile{
                width: 2.3rem;
                height: 2.3rem;
              }
              &:hover{
                @include trans;
                box-shadow: 0px 3px 2px 0px #ddd;
              }
              img{
                width: 1.2rem;
                @include mobile{
                  width: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .colR{
      width: 50%;
      position: fixed;
      right: 0;
      bottom: 0;
      top:267px;
      z-index: 1;
      @include ipad_sm{
        width: 100%;
        position: relative;
        top: unset;
      }
      .outer_map{
        min-height: 100%;
        overflow: hidden;
        height: 700px;
        position: relative;
        .map{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: inherit;
          overflow: hidden;
          iframe{
            height: 100%;
          }
        }
      }
    }
  }
}
// =========searches_banner_saved==========
.searches_banner_saved{
  h3{
    font-family: 'Bold';
    margin-bottom: 0;
  }
  .dim_sm_img{
    width: 175px;
  }
  
}
.searches_saved_sec{
  padding:4rem 0;
  background-color: $light;
  .searches_blk{
    position: relative;
    h4{
      font-size: 2.2rem;
      color: $dark;
      margin-bottom: 2rem;
    }
    .flex{
      gap: .7rem;
      max-width: 44rem;
      button{
        background: #D3D5DA;
        border: none;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        color: #757575;
        font-size: 1.6rem;
        padding: 1rem 2rem;
        @include mobile_sm{
          font-size: 1rem;
        }
      }
    }
    .time{
      color: rgba($dark,0.4);
      font-size: 1.6rem;
      margin: 1rem 0;
      @include mobile_sm{
        font-size: 1rem;
      }
    }
    .act_btn{
      @include pos($b:1rem,$l:null,$t:null);
      @include flex;
      gap: .7rem;
      a{
        @include flex(center,center);
        width: 3.6rem;
        height: 3.6rem;
        @include radius(50px);
        @include mobile_sm{
          width: 2.2rem;
          height: 2.2rem;
        }
        img{
          width: 1.4rem;
          @include mobile_sm{
            width: 1.2rem;
          }
        }
        &.edit{
          background: #5B51CF;
        }
        &.delete{
          background: #9B1D1D;
        }
      }
    }
  }
  .devide_line{
    border: 1px solid #D7C1A2;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.view_searches_btn{
  img{
    width: 2rem;
  }
}
.table_view_sec{
  margin-top: 178px;
  background-color: $light;
  padding: 0;
  .inner_tbl{
    @include ipad{
      overflow-x: auto;
    }
  }
  table{
    width: 100%;
    @include ipad{
      width: 125rem;
    }
    thead{
      background: #FDFAF7;
      tr{
        th{
          padding:1rem 2rem;
          font-family: 'Bold';
          color: #121900;
        }
      }
    }
    tbody{
      tr{
        border-bottom: 1px solid #FBF6EF;
        td{
          padding:1rem 2rem;
          font-family: 'SemiBold';
          color: #121900;
          ul{
            &.social{
              @include list;
              @include flex;
              gap: .7rem;
              li{
                a{
                  width: 3.4rem;
                  height: 3.4rem;
                  @include flex(center,center);
                  @include radius(50px);
                  background: #EDEEEC;
                  @include trans;
                  img{
                    width: 1.5rem;
                    @include trans;
                  }
                  &:hover{
                    @include trans;
                    background-color:#f5e8d6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination_blk{
    @include flex;
    padding: 2rem 2rem;
    span{
      color: #121900;
      font-size: 1.4rem;
    }
    .colL{
      width: 40%;
    }
    .colR{
      width: 60%;
      .pagination_slider{
        @include flex;
        gap: 1.5rem;
        span{
          margin-right: 2rem;
          align-self: center;
        }
        button{
          width: 3.2rem;
          height: 3.2rem;
          @include flex(center,center);
          border: 1.5px solid #D7C1A2;
          box-shadow: 0px 4px 40px rgba(14, 8, 84, 0.1);
          @include radius(50px);
          background-color: transparent;
          img{
            width: .7rem;
            opacity: 0.5;
          }
          &.prev_btn{
            padding-right: 0.7rem;
          }
          &.next_btn{
            img{
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              -o-transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
// =============top_strip_export======
.top_strip_export{
  padding: 1rem 0;
  border-bottom: 1.5px solid #FBF6EF;
  .flex{
    justify-content: space-between;
    a{
      @include flex;
      gap: 1rem;
      color: #888C80;
      font-size: 2rem;
      font-family: 'Bold';
      @include mobile_sm{
        font-size: 1.8rem;
      }
      img{
        width: 2rem;
        align-self: center;
      }
    }
    .label_info{
      @include flex;
      gap: 2rem;
      @include mobile_sm{
        gap: 1rem;
      }
      small{
        color: rgba($dark,0.6);
        font-size: 1rem;
        align-self: center;
        @include mobile_sm{
          font-size: .8rem;
        }
      }
      .pro_ress{
        background: #7065F0;
        width: 19.5rem;
        height: .8rem;
        @include radius(10px);
        align-self: center;
        @include mobile_sm{
          width: 6rem;
        }
      }
    }
  }
}
.export_sec{
  padding: 2rem 0 4rem;
  form{
    .lbl_heading{
      color: #121900;
      font-family: 'Medium';
      font-size: 1.4rem;
    }
    .field_row{
      .form_blk{
        .input{
          height: 4.8rem;
          border: 1px solid #D7C1A2;
          background-color: $light;
          @include radius(8px);
          font-size: 1.6rem;
          color: #4F5344;
        }
      }
      .col-md-5{
        @include mobile_sm{
          width: 100%
        }
      }
      .col-md-3{
        @include mobile_sm{
          width: 100%
        }
      }
    }
    .file_output_row{
      .btn_lbl_blk{
        @include flex;
        border: 1px solid #D7C1A2;
        overflow: hidden;
        @include radius(8px);
        width: fit-content;
        .btn_lbl{
          background-color: transparent;
          @include radius(8px);
          height: 4.8rem;
          @include flex(center,center);
          border: none;
          color: #4F5344;
          padding: .4rem 2rem;
          &.active{
            background-color: #D7C1A2;
            color: $color;
          }
          &:nth-child(1){
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          &:nth-child(2){
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
    .preview_blk{
      max-width: 1240px;
      .outer_table{
        overflow-x: auto;
      }
      table{
        width: 100%;
        border: 1px solid #00000023;
        @include mobile_sm{
          width: 70rem;
        }
        thead{
          tr{
            th{
              font-size: 1rem;
              color: rgba($dark,0.8);
              font-family: 'SemiBold';
              padding: 1rem 2rem;
            }
            border-bottom: 1px solid #00000023;
          }
        }
        tbody{
          tr{
            td{
              font-size: 1.2rem;
              color: rgba(#121900,0.6);
              font-family: 'SemiBold';
              padding: 1rem 2rem;
              > a{
                font-size: 1.2rem;
                color: rgba(#121900,0.6);
                font-family: 'SemiBold';
              }
            }
            &:not(:last-child){
              border-bottom: 1px solid #00000023;
            }
          }
        }
      }
    }
    .btn_lst_export{
      margin-top: 1rem;
      gap: .7rem!important;
      .site_btn{
        &.yellow_blank{
          border: 1px solid #D7C1A2!important;
          color: #4F5344;
        }
        @include mobile_sm{
          font-size: 1.2rem;
          padding: 0 1.1rem;
        }
      }
    }
    .property_fields{
      border-bottom: 1.5px solid #FBF6EF;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      max-width: 1240px;
      .head_field{
        @include flex(center,space-between);
        h6{
          font-size: 1.6rem;
          font-family: 'SemiBold';
          color: #121900;
        }
        a{
          color: rgba(#121900,0.5);
          font-size: 1.6rem;
          font-family: 'SemiBold';
        }
      }
      .check_flex{
        @include flex;
        @include mobile_sm{
          flex-direction: column;
          gap: 0;
        }
        gap: 2rem;
        .check_inner{
          flex: 1;
          
          .lbl_btn{
            padding-left: 3rem;
            position: relative;
            margin-bottom: 1rem;
            label{
              color: rgba(#121900,0.7);
              font-size: 1.6rem;
              font-family: 'SemiBold';
            }
            &:before{
              @include pos($l:0,$t:3px,$b:null,$r:null);
              content: '';
              background-image: url(../images/send_icon.svg);
              @include bg(contain);
              width: 1.7rem;
              height: 1.8rem;
            }
          }
        }
      }
    }
  }
}
.back_lbl_nav{
  align-self: center;
  margin-right: 1rem;
  @include ipad_sm{
    display: none
  }
  a{
    @include flex;
    gap: 1rem;
    color: #888C80;
    font-size: 2rem;
    font-family: 'Bold';
    img{
      width: 2rem;
      align-self: center;
    }
  }
}
// =================detail_property_page==============
.detail_property_page{
  margin-top: 157px;
  .listing_relative{
    .colL{
      padding: 0;
      .inner{
        padding:2rem 2.5rem;
        position: relative;
        .like_btn{
          @include pos($t:2rem,$r:2rem,$b:null,$l:null);
          button{
            width: 4rem;
            height: 4rem;
            @include radius(50%);
            @include flex(center,center);
            border: 1.5px solid #D7C1A2;
            background-color: $light;
            img{
              width: 1.6rem;
            }
          }
        }
        p{
          &.sm_pera{
            font-size: 1.4rem;
            color: rgba(#121900,0.5);
            margin-bottom: 1rem;
          }
        }
        h4{
          font-family: 'Bold';
          color: #121900;
          font-size: 2rem;
          margin-bottom: .7rem;
        }
        .tabs_filter{
          margin-top: 1.5rem;
          .react-tabs__tab-list {
            border-bottom: 1.5px solid #F9F1E7;
            padding-bottom: 1rem;
            @include ipad_sm{
              flex-wrap: wrap;
            }
            .react-tabs__tab{
              button{
                &.blank{
                  height: 4rem;
                  font-size: 1.4rem;
                  border: 1px solid #E5E7EB;
                  @include radius(8px);
                  padding: 0 1.3rem;
                  color: #121900;
                  @include mobile_sm{
                    padding: 0 1rem;
                  }
                }
              }
              &.react-tabs__tab--selected{
                button{
                  &.blank{
                    border: 1px solid $color;
                    background-color: $color;
                    color: #ffffff;
                  }
                }
              }
            }
          }
          .lot_blk_flex{
            @include flex;
            @include mobile{
              flex-wrap: wrap;
            }
            .col{
              width: 50%;
              padding-bottom: 2rem;
              @include mobile{
                width: 100%;
              }
              &:first-child{
                padding-right: 1rem;
                @include mobile{
                  padding-right: 0;
                }
              }
              &:last-child{
                padding-left: 1rem;
                @include mobile{
                  padding-left: 0;
                }
              }
            }
          }
          .lot_info_blk{
            padding: 2rem;
            border: 1.5px solid #F9F1E7;
            @include radius(8px);
            background-color: $light;
            h4{
              font-size: 1.6rem;
              font-family: 'Bold';
              color: #121900;
              margin-bottom: 1.3rem
            }
            ul{
              @include list;
              li{
                @include flex(center,space-between);
                &:not(:last-child){
                  margin-bottom: 1rem;
                }
                span{
                  font-size: 1.4rem;
                  color: #121900;
                  &:first-child{
                    opacity: 0.5;
                    @include mobile{
                      flex: 1;
                    }
                  }
                  &:last-child{
                    font-family: 'Bold';
                    @include mobile{
                      flex:.8;
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
          .owner_blk_detail{
            .owner_card{
              background: #FDFAF7;
              border: 1.5px solid #D7C1A2;
              @include radius(8px);
              padding: 2rem;
              .card_head{
                @include flex(center,space-between);
                p{
                  color: #121900;
                  opacity: 0.5;
                  margin-bottom: 0
                }
                .locate_right{
                  @include flex;
                  gap: .5rem;
                  span{
                    &:first-child{
                      font-size: 1.4rem;
                      color: #121900;
                      opacity: 0.7;
                      align-self: center;
                    }
                    &:last-child{
                      color: #121900;
                      font-family: 'Bold';
                    }
                  }
                }
              }
              .owner_info{
                @include flex;
                gap: 1.5rem;
                margin: 2rem 0;
                .owner_icons{
                  width: 48px;
                  height: 48px;
                  background: #833741;
                  @include radius(50%);
                  overflow: hidden;
                  @include flex(center,center);
                  span{
                    font-size: 20px;
                    font-family: 'SemiBold';
                    color: $light;
                  }
                }
                .cnt{
                  align-self: center;
                  flex: 1;
                  h6{
                    font-size: 1.6rem;
                    color: #121900;
                    font-family: 'Bold';
                    margin-bottom: .2rem;
                  }
                  p{
                    font-size: 1.4rem;
                    color: #121900;
                    opacity: 0.5;
                  }
                }
              }
              .tiles_flex{
                @include flex;
                margin: -.5rem;
                @include mobile{
                  flex-direction: column;
                }
                .col{
                  width: calc(100% / 3);
                  padding: .5rem;
                  @include mobile{
                    width: 100%;
                  }
                  .inner{
                    padding: 1.3rem;
                    @include radius(8px);
                    background-color: $light;
                    p{
                      color: #121900;
                      opacity: 0.7;
                      font-size: 1.3rem;
                      margin-bottom: .8rem;
                    }
                    h6{
                      color: #121900;
                      font-size: 1.6rem;
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
            .contacts{
              margin-top: 2rem;
              border: 1.5px solid #D7C1A2;
              @include radius(8px);
              padding: 2rem;
              h3{
                color: #121900;
                font-size: 2rem;
                margin-bottom: 3rem
              }
              .contact_info{
                @include flex;
                gap: 1.5rem;
                .dp{
                  width: 5.6rem;
                  height: 5.6rem;
                  @include radius(50%);
                  overflow: hidden;
                  background: #D7C1A2;
                  img{
                    @include object;
                  }
                }
                .cnt{
                  flex: 1;
                  align-self: center;
                  h5{
                    font-size: 1.8rem;
                    color: #121900;
                    font-family: 'Medium';
                    margin-bottom: .5rem;
                    @include mobile_sm{
                      font-size: 1.5rem;
                    }
                  }
                  p{
                    color: #121900;
                    opacity: 0.5;
                  }
                }
              }
              .devide_line{
                border-top: 1.5px solid #FBF6EF;
                width: 100%;
                margin: 2rem 0;
              }
              .contact_information{
                h4{
                  color: #121900;
                  font-size: 1.8rem;
                  font-family: 'Medium';
                  margin-bottom: 2rem
                }
                .contact_info{
                  .dp{
                    @include flex(center,center);
                    img{
                      object-fit: contain;
                      width: 1.7rem;
                    }
                  }
                  .cnt{
                    h5{
                      @include flex;
                      gap: 1rem;
                      @include mobile_md{
                        flex-wrap: wrap;
                      }
                      span{
                        opacity: 0.25;
                      }
                    }
                    p{
                      span{
                        display: block;
                        @include mobile_md{
                          display: inline;
                        }
                      }
                    }
                  }
                }
                  
              }
            }
          }
        }
      }
    }
    .colR{
      top: 247px;
      @include ipad_sm{
        top: unset;
      }
    }
  }
}
.text_new_search{
  align-self: center;
  margin-right: 1rem;
  @include ipad_sm{
    display: none;
  }
  h4{
    font-family: 'Bold';
    color: #121900;
    font-size: 2rem;
    margin-bottom: .5rem;
  }
  p{
    color: #1D2900;
    opacity: 0.5;
    margin-bottom: 0;
  }
}
// ===============owned_property_page===========
.owned_property_page{
  margin-top: 183px;
  padding: 2rem 0px;
  .main_heading{
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .owner_grid{
    .colL{
      width: 70%;
      padding-right: 1rem;
      @include ipad_sm{
        width: 100%;
        padding-right: 0;
      }
      .inner{
        background: #FDFAF7;
        border: 1.5px solid #D7C1A2;
        @include radius(8px);
        padding: 2rem;
        .mini_heading{
          font-family: 'Medium';
          color: #121900;
          opacity: 0.5;
          margin-bottom: 1.5rem;
        }
        .tiles_flex{
          @include flex;
          margin: -.5rem;
          @include mobile{
            flex-direction: column;
          }
          .col{
            width: 22.2rem;
            padding: .5rem;
            @include mobile{
              width: 100%;
            }
            .inner{
              padding: 1.3rem;
              @include radius(8px);
              background-color: $light;
              p{
                color: #121900;
                opacity: 0.7;
                font-size: 1.3rem;
                margin-bottom: .8rem;
              }
              h6{
                color: #121900;
                font-size: 1.6rem;
                margin-bottom: 0;
              }
            }
          }
        }
        .key_state_flex{
          background-color: $light;
          padding: 2rem 1rem;
          @include radius(8px);
          .col{
            padding:0rem 2rem;
            align-self: center;
            &:not(:last-child){
              border-right: 1.5px solid #D7C1A2;
              @include ipad_sm{
                border-right: none;
              }
            }
            @include ipad_sm{
              width: calc(100% / 2);
              margin: 2rem 0;
            }
            &:nth-child(odd){
              @include ipad_sm{
                border-right: 1.5px solid #D7C1A2;
              }
            }
            h6{
              color: #121900;
              font-size: 1.6rem;
              font-family: 'SemiBold';
              margin-bottom: .5rem;
            }
            p{
              color: #121900;
              opacity: 0.7;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .colR{
      width: 30%;
      padding-left: 1rem;
      @include ipad_sm{
        width: 100%;
        padding-left: 0;
        margin-top: 2rem;
      }
      iframe{
        height: 301px;
      }
    }
  }
  & + .table_view_sec{
    margin-top: 0!important;
  }
}
// ===============companies_table_page============
.companies_table_page{
  padding:3rem 0;
  .main_heading{
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  table{
    tr{
      th,td{
        word-break: keep-all;
      }
    }
  }
}
// ============owner_overview_page===========
.owner_overview_page{
  .chart_flex{
    background: #FDFAF7;
    border: 1.5px solid #D7C1A2;
    @include radius(8px);
    padding: .5rem;
    @include ipad{
      justify-content: center;
    }
    @include mobile_sm{
      flex-direction: column;
    }
    
    .col{
      width: 25%;
      padding: 1rem;
      @include ipad{
        width: calc(100%/3);
      }
      @include ipad_sm{
        width:40%;
      }
      @include ipad{
        width:45%;
      }
      @include mobile_md{
        width:100%;
      }
      .inner{
        border: 0.5px solid #D7C1A2;
        @include radius(8px);
        padding: 1.5rem;
        background-color: $light;
        position: relative;
        @include mobile_xs{
          padding: 0.7rem;
        }
        p{
          color: #495039;
          font-size: 1.4rem;
          font-family: 'Regular';
          margin-bottom: .5rem;
          position: relative;
          z-index: 1;
        }
        h4{
          color: #495039;
          font-size: 2rem;
          font-family: 'Bold';
          // margin-bottom: -3rem;
          margin-bottom: 2rem;
          position: relative;
          z-index: 1;
        }
        
        .mid_text{
          @include pos($t:14px,$l:0,$b:0px,$r:0);
          color: rgba(#121900,0.7);
          font-size: 1.6rem;
          @include radius(50%);
          overflow: hidden;
          width: 160px;
          height: 115px;
          margin: auto;
          z-index: 1;
          @include flex(center,center,column);
          text-align: center;
          line-height: 1.2;
          span{
            display: block;
            color: #121900;
            font-family: 'Bold';
            font-size: 3.8rem;
          }
        }
      }
    }
  }
}
.drop_cnt{
  &.medium_medium{
    padding: 4rem!important;
    left: auto!important;
    right: 0!important;
    background: #FDFAF7!important;
    min-width: 83rem;
    max-width: 83rem;
    @include ipad_sm{
      left: 0!important;
      top: 37.7rem!important;
    }
    .contact_drop_inner{
      .flex_info{
        @include flex;
        gap: 1.5rem;
        margin-bottom: 2rem;
        .dp{
          background: #D9D9D9;
          @include radius(50%);
          overflow: hidden;
          width: 7rem;
          height: 7rem;
          @include flex(center,center);
          @include mobile{
            width: 3.9rem;
            height: 3.9rem;
          }
          img{
            object-fit: contain;
            width: 3.1rem;
            @include mobile{
              width: 2rem;
            }
          }
        }
        .cntnt{
          flex: 1;
          align-self: center;
          h5{
            font-family: 'SemiBold';
            margin-bottom: .5rem;
            font-size: 1.8rem;
            opacity: 0.8;
            @include mobile{
              font-size: 1.6rem;
            }
          }
          p{
            font-size: 1.4rem;
            opacity: 0.6;
            @include mobile{
              font-size: 1.1rem;
            }
          }
        }
      }
      .flex_flex{
        @include flex;
        @include ipad_sm{
          flex-wrap: wrap;
        }
        .col{
          width: 50%;
          padding:1rem 0;
          @include ipad_sm{
            width: 100%;
          }
          &:first-child{
            padding-right: 5rem;
            @include ipad_sm{
              padding-right: 0;
            }
          }
          &:last-child{
            padding-left: 2rem;
            border-left: 1px solid rgba($dark,0.2);
            @include ipad_sm{
              padding-left: 0;
              border-left: none
            }
          }
          .hide_big_sc{
            display: none;
            @include ipad_sm{
              display: block;
              border-bottom: 1px solid rgba($dark,0.2);
              padding-bottom: 1rem;
              opacity: 0.8;
              font-family: 'Bold';
              font-size: 1.4rem;
              color: #121900;
              margin-bottom: 0;
            }
          }
          ul{
            @include list;
            padding-top: 2rem;
            li{
              @include flex(center,space-between);
              &:not(:last-child){
                padding-bottom: 2.5rem;
              }
              .left_cnt{
                @include flex;
                gap: 1.5rem;
                .social_icon{
                  width: 2.7rem;
                  height: 2.7rem;
                  background: rgba(#D9D9D9,0.4);
                  @include flex(center,center);
                  @include radius(50%);
                  img{
                    width: 1.4rem;
                  }
                }
                .cn_tnt{
                  align-self: center;
                  h5{
                    color: $dark;
                    font-size: 1.4rem;
                    font-family: 'Medium';
                    margin-bottom: .5rem;
                  }
                  p{
                    color: rgba($dark,0.7);
                    font-size: 1.1rem;
                  }
                }
              }
              .right_cnt{
                @include flex;
                gap: .7rem;
                .mini_image{
                  width: 9.5px;
                  height: 9.5px;
                  align-self: center;
                }
              }
            }
          }
        }
        &.head_flex{
          margin-top:2rem;
          @include ipad_sm{
            display: none;
          }
          .col{
            &:last-child{
              border-left: none!important;
            }
            p{
              opacity: 0.8;
              font-family: 'Bold';
              font-size: 1.4rem;
              color: #121900;
            }
          }
          border-bottom: 1px solid rgba($dark,0.2);
        }
      }
    }
  }
}
.tui-chart-chartExportMenu-area{
  display: none;
}
// =================social_info_sec============
.social_info_sec{
  .flex{
    max-width: 90rem;
    margin: auto;
    @include ipad_sm{
      max-width: 100%;
    }
    .col{
      width: 50%;
      padding: 0 3rem;
      margin:3rem 0;
      @include mobile{
        width: 100%;
        padding:3rem 0;
        margin: 0;
      }
      &:nth-child(even){
        border-left: 1.5px solid rgba($dark,0.15);
        @include mobile{
          border-left: none;
        }
      }
      &:first-child{
        padding-top: 0
      }
      &:last-child{
        padding-bottom: 0
      }
      &:not(:first-child){
        @include mobile{
          border-top: 1.5px solid rgba($dark,0.15);
        }
      }
      .inner{
        @include trans;
        padding: 2rem;
        height: 100%;
        &:hover{
          box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.06);
          @include trans;
        }
      }
      .sm_icon{
        width: 5.4rem;
        height: 5.4rem;
        background: #FDD9D7;
        @include radius(8px);
        margin-bottom: 2rem;
        padding: 1.3rem;
      }
      .cnt{
        h5{
          color: #252511;
          font-size: 2.4rem;
          margin-bottom: 1.5rem;
        }
        p{
          font-size: 2rem;
          color: #666666;
          font-family: 'Regular';
          line-height: 140%;
        }
        a{
          color: #5F5454;
          display: flex;
          gap: 1rem;
          width: fit-content;
          img{
            width: 1rem;
            align-self: center;
            margin-bottom: -0.3rem;
          }
        }
      }
    }
  }
}
// ========specific css properties======
.only_search_icon{
  @include mobile_md{
    width: 4.8rem!important;
    height: 4.8rem!important;
    padding: 0;
    top: 8px!important;
    right: 8px!important;
  }
  .hide_big_src{
    display: none;
    @include mobile_md{
      display: flex;
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
    }
  }
  .hide_sm_src{
    display: block;
    @include mobile_md{
      display: none;
    }
  }
}
.mobile_top_filter_sec{
  display: none;
  @include ipad_sm{
    display: block;
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    padding: 1.5rem 0;
    z-index: 4;
    background-color: $light;
    padding-bottom: 1rem;
    .mobile_filter_nav{
      @include flex(center,space-between);
      .colL{
        @include flex;
        gap: 1rem;
        > * {
          align-self: center;
          font-size: 1.6rem;
          color: rgba(#252511,0.5);
          &:last-child{
            color: #252511;
          }
        }
      }
      .colR{
        > button{
          border: 1.5px solid #D7C1A2;
          @include radius(8px);
          background-color: $light;
          width: 4rem;
          height: 4rem;
        }
        &.drop_filter{
          position: relative;
          .drop_cnt{
            position: fixed;
            max-width: 88%;
            min-width: 88%;
            left: 0!important;
            right:0!important;
            top: 15rem;
            margin: auto;
            padding: 1.5rem;
            &.active{
              margin-top: auto;
            }
            .react-tabs__tab-list{
              flex-wrap: wrap;
              .react-tabs__tab{
                button{
                  .blank{
                    padding: 0 1.1rem;
                  }
                }
              }
            }
            .react-tabs__tab-panel{
              .react-tabs__tab-list{
                grid-row-gap: 13px;
              }
            }
            .check_flex{
              flex-direction: column;
              gap: 2rem;
              .col{
                ul{
                  li{
                    .lbl_btn{
                      label{
                        flex: 1;
                        span{
                          font-size: 1.2rem!important;
                        }
                      }
                    }
                  }
                }
              }
            }
            .mix_half_flex{
              gap: 2rem;
              .col{
                width: 100%;
                &:nth-child(odd){
                  padding-right: 0;
                }
                &:nth-child(even){
                  padding-left: 0;
                }
                .form_blk{
                  h6{
                    font-size: 1.4rem;
                    color: #121900;
                    font-family: 'Medium';
                  }
                }
                .half_flex{
                  gap: 1rem;
                  display: flex;
                  flex-direction: column;
                  .half_field{
                    .input{
                      height: 4.8rem;
                    border: 1.5px solid #D7C1A2;
                    color: #BCC3AA;
                    @include radius(8px);
                    }
                  }
                  span{
                    width: 1.1rem;
                    height: 0.15rem;
                    background-color: #D7C1A2;
                    align-self: center;
                  }
                }
                &.nested_half_flex{
                  flex-direction: column;
                  gap: 1rem;
                }
                .boolean_flex{
                  display: flex;
                  margin-bottom: 2rem;
                  flex-wrap: wrap;
                  gap: 1rem;
                  .lbl_btn{
                    label{
                      margin: 0;
                    }
                  }
                }
              }
              &.owner_flex{
                .col{
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .half_flex_form{
                  .padding_right_span{
                    padding-right: 0;
                  }
                }
              }
            }
            .btn_blk{
              margin-top: 2rem;
            }
            .footer_filter_btn{
              flex-direction: column;
              gap: 1rem;
              margin-top: 0;
              .text_left{
                align-self: flex-start;
              }
            }
          }
        }
      }
    }
  }
}
.with_filter_sec{
  @include ipad{
    margin-top: 222px;
  }
  @include ipad_sm{
    margin-top: 343px;
  }
  @include mobile_sm{
    margin-top: 305px;
  }
}

.mrgn_3rem{
  @include ipad_sm{
    margin-top: 3rem;
  }
}
.filter_height{
  @include ipad_sm{
    height: 70%;
    overflow: auto;
  }
}
.br_mrgn_10{
  @include ipad_sm{
    padding-bottom: 1rem;
  }
}